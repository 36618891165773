<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title *ngIf="screenType=='Accpet'||screenType=='Comment'" class="m-0 dialog-title text-center w-100">Confirmation</h2>
    <h2 mat-dialog-title *ngIf="screenType=='Collaboration_Remove'||screenType=='Collaboration_Add'" class="m-0 dialog-title text-center w-100">Confirmation</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='onDoubleClick'" class="m-0 dialog-title text-center w-100">Rename label</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='checkmailsyncststus'" class="m-0 dialog-title text-center w-100">Request Email Sync for Trial Account</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='RequestedMailpermission'" class="m-0 dialog-title text-center w-100">Please Wait for Validation</h2>
    <h2 mat-dialog-title  *ngIf="screenType=='ResetLabels'" class="m-0 dialog-title text-center w-100">Message</h2>
  </div>

  <mat-dialog-content class="mr-0 ml-0">
    <!-- Accpet  -->
<ng-container *ngIf="screenType=='Accpet'" >
  <div class="row mt-4">
    <div class="col-12">
      <p style="font-size: 18px;">Are you sure you want to <img src="../../../assets/images/iconsForsvg/approval.svg" alt=""> the request for <br> <mat-icon style="color: green;">assignment_turned_in</mat-icon><b>{{entitydata?.entityName}}</b> ?</p>
    </div>
  </div>
  <div class="row  justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Apply"|translate}}</button>
    </div>
</div>
</ng-container>
<!-- Comment  -->
<ng-container *ngIf="screenType=='Comment'" >
  <div class="row mt-4">
    <div class="col-12">
      <p style="font-size: 18px;">Are you sure you want to send a <mat-icon>message</mat-icon> regarding the <br><mat-icon style="color: green;">assignment_turned_in</mat-icon><b>{{entitydata?.entityName}}</b> ?</p>
    </div>
  </div>
  <div class="row w-100">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Message</mat-label>
        <textarea matInput [(ngModel)]="message" name="message" rows="4"></textarea>
      </mat-form-field>
</div>
<div class="row  justify-content-center">
  <div class="col-4">
      <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
  </div>
  <div class="col-4">
      <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Apply"|translate}}</button>
  </div>
</div>
</ng-container>
<!-- onDoubleClick  -->
<ng-container *ngIf="screenType=='onDoubleClick'" >
  <div class="row mt-2 mb-2">
    <div class="col-10">
      <mat-form-field class="w-100 custom-form-field">
        <mat-label>New Lable Name</mat-label>
        <input matInput [(ngModel)]="NewlableName">
    </mat-form-field>
    </div>
  </div>

  <div class="row mt-4 mb-4  justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="onDoubleClickSubmit()">{{"Apply"|translate}}</button>
    </div>
  </div>
</ng-container>
<!-- Collaboration_Add  -->
<ng-container *ngIf="screenType=='Collaboration_Add'" >
  <div class="row mt-2 mb-2">
    <p>Would you like to add collaboration for your email address {{entitydata?.email}} ? </p>
  </div>

  <div class="row mt-4 mb-4  justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="Collaborationbutton()">{{"Ok"|translate}}</button>
    </div>
  </div>
</ng-container>
<!-- Collaboration_Remove  -->
<ng-container *ngIf="screenType=='Collaboration_Remove'" >
  <div class="row mt-2 mb-2">
     <p>Would you like to delete the collaboration for your email address {{entitydata?.email}} ? </p>
  </div>

  <div class="row mt-4 mb-4  justify-content-center">
    <div class="col-4">
        <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="Collaborationbutton()">{{"Ok"|translate}}</button>
    </div>
  </div>
</ng-container>

<!-- checkmailsyncststus -->
 <ng-container *ngIf="screenType=='checkmailsyncststus'">
<p>Please enter the email address you would like to request for synchronization with {{data?.data?.type}}</p>
<div class="row w-100">
  <mat-form-field appearance="fill" class="w-100">
    <mat-label>Email</mat-label> 
    <input matInput [(ngModel)]="Email" name="Email" type="email">
  </mat-form-field>
</div>
<div class="row mt-2 mb-4  justify-content-center">
<div class="col-4">
  <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"|translate}}</button>
</div>
<div class="col-4">
  <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Apply"|translate}}</button>
</div>
</div>
 </ng-container>

     <!-- RequestedMailpermission  -->
<ng-container *ngIf="screenType=='RequestedMailpermission'" >
  <div class="row mt-4">
    <div class="col-12">
      <p style="font-size: 18px;">Your request is being processed. This may take a moment .</p>
    </div>
  </div>
  <div class="row mt-2 mb-4 justify-content-center">
    <div class="col-4">
    </div>
    <div class="col-4">
        <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Ok"|translate}}</button>
    </div>
</div>
</ng-container>

<ng-container *ngIf="screenType=='ResetLabels'" >
  <div class="notification mt-4">
    <span class="message">Reset label update done!</span>
</div>
<div class="row mt-2 mb-4 justify-content-center">
  <div class="col-8">
  </div>
  <div class="col-2">
      <button mat-raised-button class="w-100 b-blue" (click)="onSubmit()">{{"Ok"|translate}}</button>
  </div>
</div>
</ng-container>

  </mat-dialog-content>
