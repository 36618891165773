<div class="d-flex flex-row align-items-center modal-header-in-error ">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">{{"Update Password"|translate}}</h2>
    <mat-icon matDialogClose>close</mat-icon>
</div>
<mat-dialog-content class="mr-0 ml-0 p-0">
    <div class="row mt-2 mb-2 justify-content-start">
        <div class="col-12 p-0">
            <img style="margin: auto;" src="../../assets/logo/logo.png" class="imagestyle" >
        </div>
        <div class="col-12 mt-2">
            <form [formGroup]="loginForm" novalidate >
                <!-- old Password  -->
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-icon matPrefix>person</mat-icon> 
                        <mat-label>{{'old Password'|translate}}</mat-label>
                        <input matInput type="text"  formControlName="existPassword">
                        <div *ngIf="loginForm.get('existPassword').invalid && loginForm.get('existPassword').touched">
                            <mat-error *ngIf="loginForm.get('existPassword').errors?.required">
                                {{'old Password'|translate}}is {{'required'|translate}}
                            </mat-error>
                          </div>
                    </mat-form-field>

                     <!-- New Password  -->
                     <mat-form-field appearance="outline" class="w-100">
                        <mat-icon matPrefix>lock</mat-icon>
                        <mat-label>{{'"New Password'|translate}} <span style="color: red;">*</span></mat-label>
                        <input matInput [type]="fieldTextType=='text'? 'text':'password'"
                            formControlName="password">
                        <mat-icon *ngIf="fieldTextType=='password'" (click)="showpassword('off')"
                            matSuffix>visibility_off</mat-icon>
                        <mat-icon *ngIf="fieldTextType=='text'" (click)="showpassword('on')"
                            matSuffix>visibility</mat-icon>
                        <div *ngIf="loginForm.get('password').touched||validatePassword()==false||loginForm.get('password').invalid">
                            <mat-error *ngIf="loginForm.get('password').touched&&loginForm.get('password').errors?.required&&loginForm.get('password').invalid">
                                {{"Password is required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="loginForm.get('password').touched&&loginForm.get('password').errors?.minlength">
                                {{"Password must be at least 8 characters long."|translate}}
                            </mat-error>
                            <mat-error *ngIf="loginForm.get('password').touched&&validatePassword()==false">
                                {{"Password must contain at least one number, one alphabet character, and one special character."|translate}}(e.g., P@ssw0rd).
                            </mat-error>
                        </div>
                    </mat-form-field>

                    <!-- Confirm Password  -->
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-icon matPrefix>lock</mat-icon>
                        <mat-label>{{'Confirm Password'|translate}}</mat-label>
                        <input matInput [type]="fieldTextType=='text'? 'text':'password'"
                            formControlName="confirmPassword">
                        <mat-icon *ngIf="fieldTextType=='password'" (click)="showpassword('off')"
                            matSuffix>visibility_off</mat-icon>
                        <mat-icon *ngIf="fieldTextType=='text'" (click)="showpassword('on')"
                            matSuffix>visibility</mat-icon>
                        <div
                            *ngIf="loginForm.get('confirmPassword').invalid && loginForm.get('confirmPassword').touched">
                            <mat-error *ngIf="loginForm.get('confirmPassword').errors?.required">
                                {{"ConfirmPassword is required"|translate}}
                            </mat-error>
                        </div>
                        <div *ngIf="loginForm.get('confirmPassword').touched">
                            <mat-error *ngIf="getpasswordmismatch()">
                                {{"Passwords do not match"|translate}}
                            </mat-error>
                        </div>
                    </mat-form-field>
            </form>


        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <div class="row justify-content-end">
            <div class="col-5">
                <button mat-raised-button (click)="updatepassword()" [disabled]="!loginForm.valid&&!getpasswordmismatch()" class="b-blue">{{'Apply'|translate}}</button>
            </div>
    </div>
</mat-dialog-actions> 