import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ManageAutomationService {

    private automationDataSource = new BehaviorSubject<any>(null);
  currentAutomationData = this.automationDataSource.asObservable();
  constructor(private http: HttpClient) { }

  updateAutomationData(data: any) {
    this.automationDataSource.next(data);
  }

  setEntityData(data: any) {
    this.automationDataSource.next(data);
  }

  createScenerio(formData: any) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Automation/CreateScenario', formData, { headers: headers })
  }

  CreateScenarioConditions(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.post(baseUrl + '/api/Automation/CreateScenarioConditions', requestData, httpOptions);
  }

  UpdateScenarioConditions(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.put(baseUrl + '/api/Automation/UpdateScenarioCondition', requestData, httpOptions);
  }

  CreateScenarioActions(requestData: any) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Automation/CreateScenarioActions', requestData, { headers: headers });
  }

  UpdateScenarioActions(requestData: any) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.put(baseUrl + '/api/Automation/UpdateScenarioAction', requestData, { headers: headers });
  }

  getEntityColoumns(data:any){
    return this.http.get(baseUrl + '/api/Automation/FindColumns?Entity=' + data);
  }

}