    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <img  style="cursor: pointer;" src="../../../assets/images/iconsForsvg/settings.svg" alt="">
           Settings
        </h2>
        <mat-icon  (click)="closepopup()">close</mat-icon>
    </div>
    <div class="row">
        <mat-tab-group>
            <!-- Collaboration  -->
            <mat-tab >
                <ng-template mat-tab-label>
                    <div style="width: 250px !important;">
                        <span>Collaboration</span>
                    </div>
                </ng-template> 
                <div class="row">
                    <div class="col-4">
                        <div class="row mt-4 mb-4">
                                <p *ngFor="let x of collaborationlist">
                                    <mat-slide-toggle [(ngModel)]="x.isCollabrative"(change)="onToggleChange($event,x)" ></mat-slide-toggle>
                                    <span style="margin-left: 5px;">{{x?.email}}</span>
                                </p><br>
                         </div>
                    </div>
                    <ng-container *ngIf="UpdateAccountAsColabrative">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field class="w-100">
                                        <input matInput  placeholder="Search">
                                        <mat-icon matSuffix class="pointer searchicon">search</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <button mat-raised-button *ngIf="applybuttonstatus" class="b-blue" (click)="onSubmiaccess()">{{"Apply"|translate}}</button>
                                </div>
                            </div>
                            <div class="row w-100" >
                                <div class="col-4" *ngFor="let x of filterforuserlist;let index=index;">
                                    <div style="display: flex;" class="displayflexstyle">
                                        <div>
                                            <mat-checkbox (click)="applybutton()" [(ngModel)]="x.checked"  >
                                            </mat-checkbox>
                                        </div>
                                        <div>
                                            <img class="avatar" style="border-radius: 50%;" *ngIf="x?.profilePicture!=''" onError="this.src='assets/avatar.svg'" 
                                            [src]="x?.profilePicture ? x?.profilePicture:'assets/avatar.svg'">
                                            <div *ngIf="x?.profilePicture==''" [style.background]="getColor(index)"  class="profile">{{getuserFLnames(x?.firstName+' '+x.lastName)}}</div>
                                        </div>  
                                        <div>
                                    <span style="margin-left: 5px;"><b>{{x?.firstName}} {{x.lastName}}</b></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
             </mat-tab>
    
            <!-- Access permission  -->
            <mat-tab >
                <ng-template mat-tab-label>
                    <div style="width: 250px !important;">
                        <span>Access permission</span>
                    </div>
                </ng-template> 
                <div>
                    <div style="display: flex;padding: 20px;">
                        <div style="width: 30%;">
                            <div  *ngFor="let x of allmails" [ngClass]="siddemenu(x)" (click)="sidemenuclick(x)">
                                <p>{{x?.email}}</p>
                            </div>
                        </div>
                        <div style="width: 70%;">
                            <div class="row mb-4"  *ngFor="let User of AccesspermissionUsers;let i = index">
                                <div class="col-12">
                                   <div>
                                    <span style="position: relative;right: 10px;top: 10px;">
                                        <mat-slide-toggle (change)="onToggleChange2($event,User,i)"></mat-slide-toggle>
                                    </span> 
                                    <span  [style.background]="getColor(i)"
                                    style="color: white; padding: 8px;border-radius: 50%;position: relative;top: 5px;margin: 5px;right: 8px;">
                                    {{getuserFLnames(User?.userName)}}</span>
                                    <span>{{User?.userName|titlecase}}</span>
                                   </div>
                                   <div>
                                  <!-- -----  -->
                                   </div>
                                </div>
                                <ng-container>
                                    <div class="row mt-4" *ngIf="seletedUserindex==i">
                                        <ng-container *ngIf="CollaboratedUsersCategories.length!=0">
                                            <div style="display: flex;margin-top: 10px;" *ngFor="let Categories of CollaboratedUsersCategories">
                                                <div style="width: 20%;"></div>
                                                <div>
                                                    <mat-checkbox [(ngModel)]="Categories.isPermitted"></mat-checkbox>
                                                </div>
                                                <div style="margin-left: 5px;">
                                                    <button style="border: none;" [ngStyle]="{ 'background-color': Categories?.bgColorCode, 'color': Categories?.textColorCode, 'border-radius': '10px','text-align': 'center','width': '100px' }">
                                                        {{Categories?.name}}
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
    
            </mat-tab>
    
                <!-- Categories  -->
            <mat-tab >
                <ng-template mat-tab-label>
                    <div style="width: 250px !important;">
                        <span>Categories</span>
                    </div>
                </ng-template> 
                <div>
                    <div class="row justify-content-end">
                        <div class="col-2">
                            <mat-icon  (click)="addCategory()">add_box</mat-icon>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-stripedmb-4">
                                <thead>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Category</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let x of categorieslist">
                                    <td>
                                        <mat-icon [matMenuTriggerFor]="beforeMenu" class="icon-options">more_vert</mat-icon>
                                        <mat-menu #beforeMenu="matMenu" xPosition="before">
                                            <button class="fw-600" mat-menu-item (click)="Categorydetails(x)">
                                                <mat-icon >visibility</mat-icon>
                                                <span class="fw-600 color_black">View</span>
                                            </button>
                                            <button class="fw-600" mat-menu-item (click)="Categoryedit(x)" >
                                                <mat-icon>edit</mat-icon> Edit
                                            </button>
                                            <button class="fw-600" mat-menu-item (click)="Categorydelete(x)">
                                                <mat-icon>delete</mat-icon> Delete
                                            </button>
                                        </mat-menu>
                                    </td>
                                    <td>
                                        {{x?.description}}
                                    </td>
                                    <td>
                                    <button style="border: none;font-weight: 900;"  [ngStyle]="{ 'background-color': x?.bgColorCode, 'color': x?.textColorCode, 'border-radius': '10px','text-align': 'center','width': '150px' }">
                                        {{x?.name}}
                                    </button>
                                    </td>
                                    </tr>
                                    </tbody>
                             </table>
                        </div>
                    </div>
                </div>
                </mat-tab>
          </mat-tab-group>
          
    </div>






