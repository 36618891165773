import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Injector, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConformationComponent } from 'src/app/pages/management/management-initiatives/conformation/conformation.component';
import { ReminderCreateComponent } from 'src/app/pages/Reminder/reminder-create/reminder-create.component';
import { TimeManagementService } from '../../services/time-management.service';
import { CreateProjectComponent } from './create-project/create-project.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfilePickFormatService } from '../../services/profile-pick-format.service';
@Component({
  selector: 'app-time-track',
  templateUrl: './time-track.component.html',
  styleUrls: ['./time-track.component.scss'],
})
export class TimeTrackComponent implements OnInit {
  tabnumber: number = 0;
  timeTrackForm: FormGroup;

  trackOptionsForm: FormGroup;
  filteredusers: any = [];
  ReminderList: any = [];
  currentdate = new Date();

  vacationRequests: any[] = [
    {
      name: 'Maja Andev',
      date: 'Jun 28, 2022',
      message:
        "I'm requesting 40 hours of Vacation for Oct 7, 2022 - Oct 11, 2022.",
      initials: 'MA',
    },
    {
      name: 'Jennifer Caldwell',
      date: 'Jun 28, 2022',
      message:
        "I'm requesting 40 hours of Vacation for Aug 19, 2022 - Aug 23, 2022.",
      initials: 'JC',
    },
  ];

  totalRequests = 2;
  SelectedMode: any;
  afterValue: any;
  beforeValue: any;
  Projects: any;
  AdminStatus: any;
  ManagerStatus: any;
  Trackingmethod: string;


ReminderListDummy = [{triggertype : 'Notification', value: '30', durationtype: 'Minutes', triggerMoment: 'After', Parameter: 'Start'}]

  ManagerApproval: FormGroup
  projectName: any;
  FontColor: any;
  Color: any;
  previousValue: string;
  currentValue: string;
  selectedProject: any;
  Projectid: any;
  EntryTimeSheet: any;
  SingleApproveForm: FormGroup;
  AllDateApproveForm: FormGroup;
  transformedData: any;
  Mode: any;
  uniqueDates: any;
  dateEntriesMap: any;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private injector: Injector,
    private dialogRef: MatDialogRef<TimeTrackComponent>,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.AdminStatus = sessionStorage.getItem('isAdmin');
    this.ManagerStatus = sessionStorage.getItem('isManager');

   
  }

  ngOnInit(): void {

    this.timeTrackForm = this.fb.group({
      timeOption: ['', Validators.required],
    });

    this.SingleApproveForm = this.fb.group({
      ApproveSingle: [false]
    })

    this.AllDateApproveForm = this.fb.group({
      ApproveAllDate: [false]
    })

    

    this.trackOptionsForm = this.fb.group({
      eventMatching: [true],
      addreminders: [false],
      toggleOption: [false],
      selectdatetype: ['2'],
      customDays: [null]
    });

    this.ManagerApproval = this.fb.group({
      ApproveAll: [false],
      startDate: [],
      endDate: []
    })

    this.findNode()
    this.getProjects()
  }

  approveRequests(data:any, type:any){
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { data: data, type: type },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.SingleApproveForm.get('ApproveSingle').setValue(false)
        this.getPendingTimeRequests()
      } else {
        this.SingleApproveForm.get('ApproveSingle').setValue(false)
      }
    });
  }

  findNode(){
    this.injector.get(TimeManagementService).getSelectedNode().subscribe((data:any) => {
      console.log(data)
      this.Mode = data?.response[0].message
      const timeOptionValue = this.Mode === 'ClockInClockOut' ? '1' : this.Mode === 'StartEndTime' ? '2' : '3';
      this.timeTrackForm.get('timeOption').setValue(timeOptionValue);
      this.timeTrackForm.get('timeOption').updateValueAndValidity();
    })
  }

  approveAllRequests() {
    // Create an array to store all request IDs
    let allRequestIds: string[] = [];

    for (let entry of this.EntryTimeSheet) {
        // Collect all request IDs from each entry
        const requestIds = this.getRequestIdsFromEntry(entry);
        allRequestIds = [...allRequestIds, ...requestIds];
    }

    // Open confirmation dialog with all request IDs
    const dialogRef = this.dialog.open(ConformationComponent, {
        data: { data: allRequestIds.join(','), type: 'ApproveDateWise' },
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
            this.AllDateApproveForm.get('ApproveAllDate').setValue(false);
            this.getPendingTimeRequests();
        } else {
            this.AllDateApproveForm.get('ApproveAllDate').setValue(false);
        }
    });
}

getRequestIdsFromEntry(entry: any): string[] {
    return entry.userRequestDtos?.flatMap(member => 
        member.requestDtos.map(day => day.id)
    ) || []; // Return an empty array if there are no request IDs
}

  approveDateWise(data: any, type: any) {
    console.log(data)
  const requestIds: any = data;
  console.log(requestIds)
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { data: requestIds.join(','), type: type },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.AllDateApproveForm.get('ApproveAllDate').setValue(false);
        this.getPendingTimeRequests();
      } else {
        this.AllDateApproveForm.get('ApproveAllDate').setValue(false);
      }
    });
  }

  openProjectMenu(event: MouseEvent, project: any) {
    // Stop event propagation to prevent the mat-select from trying to select an option
    event.stopPropagation();
    // Open the menu programmatically if needed
    this.selectedProject = project; // Save the currently selected project if needed
  }

  createNode(){
    const timeOptionValue = this.timeTrackForm.get('timeOption').value;
    const mode = Number.isInteger(timeOptionValue) ? timeOptionValue : parseInt(timeOptionValue, 10);
    this.injector.get(TimeManagementService).createMode(mode).subscribe((data:any) => {
      if(data){
        this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
      }
    })
  }

  getProjects(){
    this.injector.get(TimeManagementService).getAllProjects().subscribe(data => {
      console.log(data)
      this.Projects = data
    })
  }

  tabChange(tabIndex: number): void {
    this.tabnumber = tabIndex;
    if (tabIndex === 0) {
      this.tabnumber = 0;
      this.ngOnInit()
    } else if (tabIndex === 1) {
      this.tabnumber = 1;
    } else if (tabIndex === 2) {
      this.tabnumber = 2;
      this.getPendingTimeRequests()
    }
  }

  NextMethod2() {
    this.ReminderList?.forEach(element => {
      if(element.isAfter) {
        this.afterValue = element.value
      } else {
        this.beforeValue = element.value
      }
    });

    this.ReminderListDummy?.forEach(element => {
      if(element.triggerMoment) {
        this.afterValue = element.value
      } else {
        this.beforeValue = element.value
      }
    })
    
    var data = {
      isEventMatching: this.trackOptionsForm.get('eventMatching').value,
      hasReminders: this.trackOptionsForm.get('addreminders').value,
      reminderBeforeMinutes: this.beforeValue,
      reminderAfterMinutes: this.afterValue,
      isApprovalRequired: this.trackOptionsForm.get('toggleOption').value,
      trackingPeriod: Number.isInteger(this.trackOptionsForm.get('selectdatetype').value) ? this.trackOptionsForm.get('selectdatetype').value : parseInt(this.trackOptionsForm.get('selectdatetype').value, 10),
      NumberOfDays: this.trackOptionsForm.get('customDays').value,
    };

    var FinalData = {};
    for (const key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        FinalData[key] = data[key];
      }
    }

    this.injector.get(TimeManagementService).createTimeTrack(FinalData).subscribe((data:any) => {
      console.log(data)
      if(data){
        this.snackBar.open(data.response[0].message, 'Undo', { duration: 2000 });
        this.dialogRef.close(true)
      }
    })
  }

  onOptionChange(selectedOption: string) {
    this.Trackingmethod = selectedOption == '1' ? 'Clock In – Clock Out': selectedOption == '2' ? 'Start Time – End Time' : 'No of Hrs'
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { option: this.Trackingmethod, type: 'TimeTrack' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.createNode()
      }
    });
  }

  getreminderststus(){
    return this.trackOptionsForm.get('addreminders').value
  }

  showreminderspopup() {
    this.trackOptionsForm.get('addreminders').setValue(true);
    this.showreminders();
  }

  showreminders() {
    const showreminders = this.dialog.open(ReminderCreateComponent, {
      width: '500px',
      data: { type: 'TimeTrack', screenType: 'CreateReminder' },

      autoFocus: false,
      disableClose: true,
    });
    showreminders.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log(result);
        result.reminderConditions.forEach((element) => {
          this.ReminderList.push(element);
          console.log(this.ReminderList);
        });
      }
    });
  }
  removereminder(index) {
    this.ReminderList.splice(index, 1);
  }

  removereminderDummy(index) {
    this.ReminderListDummy.splice(index, 1);
  }

  getdateStatus(event: any) {
    if (event === '1') {
      this.trackOptionsForm
        .get('EndDate')
        ?.setValue(
          this.datepipe.transform(this.getDateAfterWeeks(1), 'yyyy-MM-dd')
        );
    } else if (event === '2') {
      this.trackOptionsForm
        .get('EndDate')
        ?.setValue(
          this.datepipe.transform(
            this.trackOptionsForm.value.StartDate,
            'yyyy-MM-dd'
          )
        );
    }
  }

  getDateAfterWeeks(weeks: any) {
    const currentDate = new Date(this.trackOptionsForm.value.StartDate);
    const futureDate = new Date(
      currentDate.getTime() + weeks * 7 * 24 * 60 * 60 * 1000
    ); // Add weeks in milliseconds
    const year = futureDate.getFullYear();
    const month = futureDate.toLocaleString('default', { month: 'long' });
    const day = futureDate.getDate();
    // Format the date as "Month Day, Year"
    const formattedDate = `${month} ${1 - day}, ${year}`;

    return formattedDate;
  }

  approveRequest(request: any) {
    console.log('Approved:', request);
  }

  denyRequest(request: any) {
    console.log('Denied:', request);
  }

  AddProject(data:any, type:any) {
    const TimeTrackDialog = this.dialog.open(CreateProjectComponent, {
      width: '400px',
      autoFocus: false,
      disableClose: true,
      data: {data: data, type:type}
    });

    TimeTrackDialog.afterClosed().subscribe((result) => {
      console.log(result)
      this.projectName = result.ProjectName
      this.FontColor = result.fontColor
      this.Color = result.color
      this.Projectid = result?.id

      if(result.id != null) {
        this.injector
        .get(TimeManagementService)
        .updateProject(this.Projectid, this.projectName, this.Color, this.FontColor)
        .subscribe((result:any) => {
          console.log(result);
          if (result) {
            this.snackBar.open(result?.response[0].message,'Undo', { duration: 2000 });
            this.getProjects()
          }
        });
      } else {
        this.injector
        .get(TimeManagementService)
        .createprojects(this.projectName, this.Color, this.FontColor)
        .subscribe((result:any) => {
          console.log(result);
          if (result) {
            this.snackBar.open(result?.response[0].message,'Undo', { duration: 2000 });
            this.getProjects()
          }
        });
      }
 
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  getPendingTimeRequests(){
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const distanceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;  
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() + distanceToMonday);  
  
    // Calculate the end of the week (Friday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4);
  
    // Format the start and end dates (yyyy-mm-dd)
   const formattedStartDate = startOfWeek.toISOString().split('T')[0];
   const formattedEndDate = endOfWeek.toISOString().split('T')[0];

    this.ManagerApproval.get('startDate').setValue(formattedStartDate)
    this.ManagerApproval.get('endDate').setValue(formattedEndDate)

    this.injector.get(TimeManagementService).getTimesheetPendings(formattedStartDate, formattedEndDate, '').subscribe(data => {
      console.log(data)
      this.EntryTimeSheet = data

      var uniqueDates = [];
      var dateEntriesMap = {};
  
      // Transform data and populate unique dates and dateEntriesMap
      const transformedData = this.EntryTimeSheet?.reduce((result, entry) => {
        // Check if entry has a date and is formatted correctly
        if (entry?.date) {
          // Ensure the date is included in uniqueDates even if there are no userRequestDtos
          if (!uniqueDates.find(d => d.date === entry.date)) {
            uniqueDates.push({
              date: entry.date,
              fullDayName: entry.fullDayName,
              pendingApprovalCount: entry.pendingApprovalCount || 0, // Default to 0 if not available
              totalDuration: entry.totalDuration || "00:00:00",
              requestIds: [] 
            });
          }
        }

        const dateEntry = uniqueDates.find(d => d.date === entry.date);
      
        if (entry?.userRequestDtos) {
          entry.userRequestDtos.forEach(user => {
            let existingUser = result.find(u => u.User.creatorId === user.creatorId);
      
            // Initialize dateEntriesMap for user if not already present
            if (!dateEntriesMap[user.creatorId]) {
              dateEntriesMap[user.creatorId] = {};
            }
      
            // Check if requestDtos exists within the user and is non-empty
            if (user?.requestDtos && user.requestDtos.length > 0) {
              user.requestDtos.forEach(request => {

                dateEntry.requestIds.push(request.id);
                // Initialize date array if not present
                if (!dateEntriesMap[user.creatorId][entry.date]) {
                  dateEntriesMap[user.creatorId][entry.date] = [];
                }
      
                // Append each request entry for the date
                dateEntriesMap[user.creatorId][entry.date].push({
                  timeEntryId: request.id,
                  startIn12Hours: request.startIn12Hours,
                  endIn12Hours: request.endIn12Hours,
                  duration: request.duration,
                  projectColor: request.projectColor,
                  projectFontColor: request.projectFontColor,
                  projectName: request.projectName,
                });
      
                // Log the dateEntriesMap entry after adding request data
                console.log('Updated dateEntriesMap entry:', dateEntriesMap[user.creatorId][entry.date]);
              });
            } else {
              console.log('No requestDtos found for user:', user);
              
              // Ensure that even if there are no requestDtos, we still have an entry for the date
              if (!dateEntriesMap[user.creatorId][entry.date]) {
                dateEntriesMap[user.creatorId][entry.date] = []; // Initialize empty array for no entries
              }
            }
      
            // Construct transformedData based on existing user
            if (existingUser) {
              existingUser.date.push({
                date: entry.date,
                fullDayName: entry.fullDayName,
              });
            } else {
              result.push({
                User: user,
                date: [{
                  date: entry.date,
                  fullDayName: entry.fullDayName,
                }]
              });
            }
          });
        } else {
          console.log('No userRequestDtos found for entry:', entry);
        }
      
        return result;
      }, []);
      
      // Assign transformed data to component variables
      this.transformedData = transformedData;
      this.uniqueDates = uniqueDates;
      this.dateEntriesMap = dateEntriesMap;

    })
  }

  getDateEntry(userId: string, date: string) {
    return this.dateEntriesMap?.[userId]?.[date] || null;
  }
  

  showResult(){
    const startDate = this.ManagerApproval.get('startDate').value; 
    const endDate = this.ManagerApproval.get('endDate').value;

    const formattedStartDate = new Date(Date.UTC(
      new Date(startDate).getFullYear(),
      new Date(startDate).getMonth(),
      new Date(startDate).getDate()
  )).toISOString().split('T')[0];

  const formattedEndDate = new Date(Date.UTC(
      new Date(endDate).getFullYear(),
      new Date(endDate).getMonth(),
      new Date(endDate).getDate()
  )).toISOString().split('T')[0];

  this.injector.get(TimeManagementService).getTimesheetPendings(formattedStartDate, formattedEndDate, '').subscribe(data => {
    console.log(data)
    this.EntryTimeSheet = data

    var uniqueDates = [];
    var dateEntriesMap = {};

    // Transform data and populate unique dates and dateEntriesMap
    const transformedData = this.EntryTimeSheet?.reduce((result, entry) => {
      // Check if entry has a date and is formatted correctly
      if (entry?.date) {
        // Ensure the date is included in uniqueDates even if there are no userRequestDtos
        if (!uniqueDates.find(d => d.date === entry.date)) {
          uniqueDates.push({
            date: entry.date,
            fullDayName: entry.fullDayName,
            pendingApprovalCount: entry.pendingApprovalCount || 0, // Default to 0 if not available
            totalDuration: entry.totalDuration || "00:00:00",
            requestIds: [] 
          });
        }
      }

      const dateEntry = uniqueDates.find(d => d.date === entry.date);
    
      if (entry?.userRequestDtos) {
        entry.userRequestDtos.forEach(user => {
          let existingUser = result.find(u => u.User.creatorId === user.creatorId);
    
          // Initialize dateEntriesMap for user if not already present
          if (!dateEntriesMap[user.creatorId]) {
            dateEntriesMap[user.creatorId] = {};
          }
    
          // Check if requestDtos exists within the user and is non-empty
          if (user?.requestDtos && user.requestDtos.length > 0) {
            user.requestDtos.forEach(request => {

              dateEntry.requestIds.push(request.id);
              // Initialize date array if not present
              if (!dateEntriesMap[user.creatorId][entry.date]) {
                dateEntriesMap[user.creatorId][entry.date] = [];
              }
    
              // Append each request entry for the date
              dateEntriesMap[user.creatorId][entry.date].push({
                timeEntryId: request.id,
                startIn12Hours: request.startIn12Hours,
                endIn12Hours: request.endIn12Hours,
                duration: request.duration,
                projectColor: request.projectColor,
                projectFontColor: request.projectFontColor,
                projectName: request.projectName,
              });
    
              // Log the dateEntriesMap entry after adding request data
              console.log('Updated dateEntriesMap entry:', dateEntriesMap[user.creatorId][entry.date]);
            });
          } else {
            console.log('No requestDtos found for user:', user);
            
            // Ensure that even if there are no requestDtos, we still have an entry for the date
            if (!dateEntriesMap[user.creatorId][entry.date]) {
              dateEntriesMap[user.creatorId][entry.date] = []; // Initialize empty array for no entries
            }
          }
    
          // Construct transformedData based on existing user
          if (existingUser) {
            existingUser.date.push({
              date: entry.date,
              fullDayName: entry.fullDayName,
            });
          } else {
            result.push({
              User: user,
              date: [{
                date: entry.date,
                fullDayName: entry.fullDayName,
              }]
            });
          }
        });
      } else {
        console.log('No userRequestDtos found for entry:', entry);
      }
    
      return result;
    }, []);
    
    // Assign transformed data to component variables
    this.transformedData = transformedData;
    this.uniqueDates = uniqueDates;
    this.dateEntriesMap = dateEntriesMap;
  
  })
  }

  formatMonthDate(fullDayName:any){
    const [day, month, dayNumber] = fullDayName?.split(' ');
    return `${month} ${dayNumber}`; // Returns format like 'Oct 28'
  }

  FormatTotalDuration(totalDuration: string): string {
    const [hours, minutes] = totalDuration?.split(':');
    return `${+hours}h ${minutes}m `;
  }

  deleteProject(data:any){
    const dialogRef = this.dialog.open(ConformationComponent, {
      data: { data: data, type: 'ProjectDelete' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getProjects()
      }
    });


  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 
}
