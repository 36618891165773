<div *ngIf="!spinner" class="totalmailbody">
    <div class="row mt-2 justify-content-end">
        <div class="col-2 col-sm-3  col-md-2 col-lg-1 col-xl-1" (click)="syncaccount()">
            <mat-icon style="cursor: pointer;">sync</mat-icon>
        </div>
        <ng-container *ngIf="Adminstatus||MailsSettings">
            <div  class=" col-2 col-sm-3  col-md-2 col-lg-1 col-xl-1"(click)="clickingsetting()">
                <img  style="cursor: pointer;" src="../../../assets/images/iconsForsvg/settings.svg" alt="">
            </div>
        </ng-container>
        <div class="col-2 col-sm-3  col-md-2 col-lg-1 col-xl-1" (click)="closepopup()">
            <mat-icon style="cursor: pointer;">cancel</mat-icon>
        </div>
    </div>
    <!-- <span>{{isMobileView}}</span><br> -->
    <div class="row" >
        <!-- one  -->
        <div class="col-sm-4  col-md-3 col-lg-2 col-xl-2" style="padding: 5px !important;" >
        <div style="margin-bottom: 20px;">
        <!-- mails  -->
        <div>
        <form [formGroup]="AccountForm"> 
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Mails</mat-label>
                <mat-select multiple (selectionChange)="selectChange($event)" formControlName="AccountList">
                    <mat-select-filter [placeholder]="'Filter'" [displayMember]="'firstName'" 
                    [array]="mailids" (filteredReturn)="mailids =$event"></mat-select-filter>
                    <mat-option [value]="x" *ngFor="let x of mailids" >
                        <span *ngIf="!x?.isCollabrative"><img src="../../../assets/images/iconsForsvg/person.svg"></span>
                        <span *ngIf="x?.isCollabrative"><img src="../../../assets/images/iconsForsvg/group.svg"></span>
                        <span style="margin-left: 5px;">{{x?.email}}</span>
                        <span style="margin-left: 5px;"><mat-icon class="icon-options">delete</mat-icon></span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </form>
        </div>
        <!-- Compose  --> 
        <div style="margin-top:5px;">
            <button mat-raised-button (click)="Composemail()"  class="R-blue w-100">
                <span><img src="../../../assets/images/iconsForsvg/new-mail.svg" alt=""></span>
                <span style="margin-left: 5px;">New Email</span>
            </button>
        </div>
        <div [ngClass]="siddemenu('Inbox') " (click)="sidemenuclick('Inbox')">
            <span>
                <img class="sidemenuicons" src="../../../assets/images/iconsForsvg/inbox.svg">
            </span>
            <span class="lablestyle">Inbox</span>
        </div>
        <div [ngClass]="siddemenu('Sent') " (click)="sidemenuclick('Sent')">
            <span>
                <img class="sidemenuicons" src="../../../assets/images/iconsForsvg/send.svg">
            </span>
            <span class="lablestyle">Sent</span>
        </div>
        <div [ngClass]="siddemenu('Draft') " (click)="sidemenuclick('Draft')">
            <span><img class="sidemenuicons" src="../../../assets/images/iconsForsvg/draft.svg"     ></span>
            <span class="lablestyle">Draft</span>
        </div>
        <div [ngClass]="siddemenu('Spam') " (click)="sidemenuclick('Spam')">
            <span><img class="sidemenuicons" src="../../../assets/images/iconsForsvg/inbox.svg"     ></span>
            <span class="lablestyle">Spam</span>
        </div>
        <div [ngClass]="siddemenu('Trash') " (click)="sidemenuclick('Trash')">
            <span><img class="sidemenuicons" src="../../../assets/images/iconsForsvg/trash.svg"     ></span>
            <span class="lablestyle">Trash</span>
        </div>
        <!-- Categories  -->
         <div style="margin-top: 10px;">
            <div class="row">
                <p style="font-weight: 900;">Categories</p>
            </div>
            <div *ngFor="let x of categorieslist" [ngClass]="siddemenu(x?.name) " (click)="sidemenuclick(x?.name)">
                <span class="lablestyle">
                    <span [ngStyle]="{'background-color': x?.bgColorCode, 'color': x?.textColorCode}" class="Categoriesstyle" style="float: left !important;">{{x?.name}}</span>
                </span>
            </div>
         </div>
    </div>
        </div>
        <!-- two  -->
         <ng-container *ngIf="!mailbodyview">
            <div class="col-sm-8  col-md-9 col-lg-4 col-xl-4"
            style="border-left: 1px solid rgb(237, 231, 231);border-right: 1px solid rgb(237, 231, 231);padding-left: 5px !important;padding-right: 5px !important;">
               <!-- search  -->
               <div>
                   <mat-form-field appearance="outline" class="w-100">
                       <mat-label style="color: lightgray;">Search</mat-label>
                       <input matInput placeholder="Placeholder">
                       <mat-icon matSuffix>search</mat-icon>
                     </mat-form-field>
               </div>
               <div class="row">
                   <mat-paginator  [pageSize]="10" [length]="mailLength" (page)="nextpage($event)"></mat-paginator>
               </div>
   
               <div style="overflow-y: scroll;height: 70vh;overflow-x: hidden;">
                   <ng-container *ngIf="allmails.length==0">
                           <h1 style="text-align: center;">No Data Found</h1>
                   </ng-container>
                   <ng-container *ngIf="allmails.length!=0">
                       <div class="mt-4" [ngClass]="gethoverclass(x)" *ngFor="let x of allmails">
                           <div style="display: flex;width: 100%;cursor: pointer;">
                               <div style="width: 10%;">
                                   <div>
                                      <img *ngIf="x.isPersonal" src="../../../assets/images/iconsForsvg/person.svg">
                                      <img *ngIf="!x.isPersonal" src="../../../assets/images/iconsForsvg/group.svg">
                                   </div>
                                   <div>
                                     <mat-icon [matMenuTriggerFor]="moreMenu">more_vert</mat-icon>
                                      <mat-menu #moreMenu="matMenu" class="eventlist">
                                          <ng-template matMenuContent>
                                              <button class="fw-600" mat-menu-item (click)="Replyemail(x)">
                                                  <img  src="../../../assets/images/iconsForsvg/reply.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item (click)="ReplyAllemail(x)">
                                                  <img  src="../../../assets/images/iconsForsvg/reply_all.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply All'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item (click)="Forwardemail(x)">
                                                  <img  src="../../../assets/images/iconsForsvg/forward.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Forward'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item >
                                                  <img  src="../../../assets/images/iconsForsvg/print.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Print'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item >
                                                  <img  src="../../../assets/images/iconsForsvg/delete.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Delete'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item >
                                                  <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark spam'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item >
                                                  <img  src="../../../assets/images/iconsForsvg/block.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'block'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item >
                                                  <img  src="../../../assets/images/iconsForsvg/download.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Download'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item >
                                                  <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark as unread'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item (click)="CreateTask(x)" >
                                                  <img  src="../../../assets/images/iconsForsvg/task.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Task'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item (click)="CreateEvent(x)">
                                                  <img  src="../../../assets/images/iconsForsvg/calendar.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Event'|translate}}</span>
                                              </button>
                                              <button class="fw-600" mat-menu-item (click)="ConverttoContact(x)">
                                                  <img  src="../../../assets/images/iconsForsvg/contacts.svg">
                                                  <span class="fw-600 color_black" style="margin-left: 5px;">{{'Convert to Contact'|translate}}</span>
                                              </button>
                                          </ng-template>
                                      </mat-menu>
                                   </div>
                                 </div>
                                 <div style="width: 90%;" (click)="getmailbody(x)">
                                     <!-- one   -->
                                     <div class="row">
                                         <div class="col-8 p-0"> 
                                             <h6 class="mb-0">{{x?.senderName}}</h6>
                                         </div>
                                         <div class="col-4">
                                             <button *ngIf="x?.isPromotions||x?.isSocial"  [ngClass]="lablecolor(x)">{{lablename(x)}}</button>
                                         </div>
                                     </div>
                                     <!-- two  -->
                                     <h6 class="fw-semibold">{{x?.subject}}</h6>
                                     <!-- three  -->
                                          <div style="display: flex;width: 100%;">
                                              <div style="display: flex;width: 90%;" class="totalmailbody">
                                                  <!-- <div>
                                                      <img class="sidemenuicons" src="../../../assets/images/iconsForsvg/star.svg"  >
                                                  </div>
                                                  <div>
                                                      <img class="sidemenuicons" src="../../../assets/images/iconsForsvg/error.svg"     >
                                                  </div> -->
                                                  <div *ngIf="x?.isEventConverted">
                                                      <img class="sidemenuicons"  (click)="showevent(x)" src="../../../assets/images/iconsForsvg/calendar.svg"  >
                                                  </div>
                                                  <div *ngIf="x?.isTaskConverted">
                                                      <img class="sidemenuicons" (click)="showtask(x)"  src="../../../assets/images/iconsForsvg/task.svg"  >
                                                  </div>
                                                  <ng-container  *ngIf="!x?.isPersonal">
                                                    <div style="display: flex;width: 100%;" *ngIf="x?.viewedUsers!=null">
                                                        <div style="display: flex;width: 90%;" class="totalmailbody" *ngIf="!x.isPersonal">
                                                                <div *ngFor="let User of x?.viewedUsers;let i = index" [style.background]="getColor(i)" 
                                                                (click)="showgroupinUser(User)" class="profile_ML">{{getuserFLnames(User?.userName)}}</div>
                                                           </div>
                                                           <div>
                                                            <p style="text-align: end;color: gray;">{{x.datetime|date:'H:mm'}}</p>
                                                           </div>
                            
                                                           <div>
                                                               <img *ngIf="!x?.isUnReaded" src="../../../assets/images/iconsForsvg/done_all.svg" alt="">
                                                           </div>
                                                    </div>
                                                   </ng-container>
                                              </div>
                                          </div>
                                 </div>
                           </div>
                      </div>
                   </ng-container>
               </div>
           </div>
         </ng-container>

        <!-- tree  -->
         <ng-container *ngIf="!isMobileView || mailbodyview">
            <div class="col-sm-8  col-md-9 col-lg-6 col-xl-6" style="padding: 5px !important;" >
                <div style="overflow-y: scroll;height: 85vh;overflow-x: hidden;" *ngIf="mailbody!=null">
                    <div  style=" display: flex;width: 100%; border-bottom: 1px solid rgb(237, 231, 231); margin-bottom: 10px;padding: 10px;">
                            <div style="width: 90%;display: flex;" class="flexdiv">
                                <div style="display: flex;width: 90%;" class="totalmailbody">
                                    <!-- <div>
                                        <img class="sidemenuicons" src="../../../assets/images/iconsForsvg/star.svg"  >
                                    </div>
                                    <div>
                                        <img class="sidemenuicons" src="../../../assets/images/iconsForsvg/error.svg"     >
                                    </div> -->
                                    <div *ngIf="mailbody?.isEventConverted">
                                        <img class="sidemenuicons"  (click)="showevent(mailbody)" src="../../../assets/images/iconsForsvg/calendar.svg"  >
                                    </div>
                                    <div *ngIf="mailbody?.isTaskConverted">
                                        <img class="sidemenuicons" (click)="showtask(mailbody)"  src="../../../assets/images/iconsForsvg/task.svg"  >
                                    </div>
                                    <ng-container  *ngIf="!mailbody?.isPersonal">
                                      <div style="display: flex;width: 100%;" *ngIf="mailbody?.viewedUsers!=null">
                                          <div style="display: flex;width: 90%;" class="totalmailbody" *ngIf="!mailbody.isPersonal">
                                                  <div *ngFor="let User of mailbody?.viewedUsers;let i = index" [style.background]="getColor(i)" 
                                                  (click)="showgroupinUser(User)" class="profile_ML">{{getuserFLnames(User?.userName)}}</div>
                                             </div>
                                      </div>
                                     </ng-container>
                                </div>
                        </div>
                      <div style="width: 10%;">
                          <button mat-icon-button [matMenuTriggerFor]="moreMenu">
                              <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #moreMenu="matMenu" class="eventlist">
                              <ng-template matMenuContent>
                                  <button class="fw-600" mat-menu-item (click)="Replyemail(mailbody)">
                                      <img  src="../../../assets/images/iconsForsvg/reply.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item (click)="Replyemail(mailbody)">
                                      <img  src="../../../assets/images/iconsForsvg/reply_all.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply All'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item (click)="Forwardemail(mailbody)">
                                      <img  src="../../../assets/images/iconsForsvg/forward.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Forward'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item >
                                      <img  src="../../../assets/images/iconsForsvg/print.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Print'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item >
                                      <img  src="../../../assets/images/iconsForsvg/delete.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Delete'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item >
                                      <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark spam'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item >
                                      <img  src="../../../assets/images/iconsForsvg/block.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'block'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item >
                                      <img  src="../../../assets/images/iconsForsvg/download.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Download'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item >
                                      <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark as unread'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item (click)="CreateTask(mailbody)"  >
                                      <img  src="../../../assets/images/iconsForsvg/task.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Task'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item (click)="CreateEvent(mailbody)"  >
                                      <img  src="../../../assets/images/iconsForsvg/calendar.svg">
                                      <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Event'|translate}}</span>
                                  </button>
                                  <button class="fw-600" mat-menu-item (click)="ConverttoContact(mailbody)">
                                    <img  src="../../../assets/images/iconsForsvg/contacts.svg">
                                    <span class="fw-600 color_black" style="margin-left: 5px;">{{'Convert to Contact'|translate}}</span>
                                </button>
                              </ng-template>
                          </mat-menu>
                      </div>
                  </div>
                  <!-- subject  -->
                  <div class="row mt-2">
                    <h2 style="font-weight: 700;">{{mailbody?.subject|titlecase}}</h2>
                </div>
                  <div class="row">
                      <div class="col-2">
                        <div  class="profile">{{getuserFLnames(mailbody?.senderName)}}</div> 
                      </div>
                      <div class="col-10 p-0">
                          <div class="row p-0 m-0">
                              <div class="col-9 p-0 m-0">
                                 <p style="font-weight: 900;margin: 0 !important;font-size: 14px;">{{mailbody?.senderName}}</p>
                                 <p style="color: gray;font-size: 12px;">{{mailbody?.senderEmail?mailbody?.senderEmail:mailbody?.fromEmail}}</p>
                                 <p *ngIf="mailbody?.toRecipients.length!=0">
                                    <b>To :</b>
                                    <span *ngFor="let x of mailbody?.toRecipients">
                                        <span>{{x}}</span>
                                    </span>
                                </p>
                                 <p *ngIf="mailbody?.ccRecipients.length!=0">
                                    <b>Cc :</b>
                                    <span *ngFor="let x of mailbody?.ccRecipients">
                                        <span>{{x}}</span>
                                    </span>
                                </p>
                                 <p *ngIf="mailbody?.bccRecipients.length!=0">
                                    <b>BCC :</b>
                                    <span *ngFor="let x of mailbody?.bccRecipients">
                                        <span>{{x}}</span>
                                    </span>
                                </p>
                              </div>
                              <div class="col-3 p-0 m-0">
                                  <button style="float: right;"   *ngIf="mailbody?.isPromotions||mailbody?.isSocial"  [ngClass]="lablecolor(mailbody)">{{lablename(mailbody)}}</button>
                              </div>
                          </div>
                      </div>
                  </div>
    
                  <div class="row mt-2">
                    <div *ngIf="mailbody?.body?.contentType=='html'">
                        <div style="overflow-x: hidden;" [innerHTML]="mailbody?.body?.content"></div>
                    </div>
                    <div *ngIf="mailbody?.body?.contentType=='text'">
                        <p>{{mailbody?.body?.content}}</p>
                    </div>
                  </div>
    
                  <div style="margin-top: 30px;">
                    <p style="margin: 0 !important;">Regards,</p>
                    <p style="font-weight: 700;">{{mailbody?.senderName}}</p>
                </div>
                  <ng-container *ngIf="mailbody?.attachmentFileDtls!=null&&mailbody?.attachmentFileDtls.length!=0">
                  <hr>
                       <p style="font-weight: 700;text-align: center;">Attachments</p>
                       <div class="row">
                          <div class="col-6 p-2" *ngFor="let x of  mailbody?.attachmentFileDtls">
                              <div class="row">
                                  <div class="col-2">
                                    <img src="../../../../assets/notecreateicons/doc-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='docx'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/png-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='png'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/png-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='PNG'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/jpg-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='jpg'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/xls-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='xlsx'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/avi-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='avi'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/pdf-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='pdf'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/ppt-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='powerpoint'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/html-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='html'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/svg.svg" *ngIf="getfiletype(x.fileName)=='svg'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/csv-svgrepo-com.svg" (click)="View(data)" *ngIf="getfiletype(x.fileName)=='csv'" class="drowdownimage" >
                                    <img src="../../../../assets/notecreateicons/txt image.jpg" (click)="View(data)" *ngIf="getfiletype(x.fileName)=='txt'" class="drowdownimage" >
                                  </div>
                                  <div class="col-10">
                                      <p>{{x?.fileName}}</p>
                                  </div>
                              </div>
                          </div>
                       </div>
                  </ng-container>
                </div>
            </div>
         </ng-container>
    </div>
</div>
<div class="overlay" *ngIf="spinner">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
