import { Component, Input, OnInit, Inject, TemplateRef, ChangeDetectorRef, Injector } from '@angular/core';
import { EditEventComponent } from './edit-event/edit-event.component';
import { EventProposalComponent } from './event-proposal-calender/event.proposal.component';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { EditProposalComponent } from './edit-proposal/edit-proposal.component';
import { MessageService } from '../../services/message.service';
import { UserDetailsComponent } from 'src/app/pages/management/manage-users/user-details/user-details.component';
import { ContactDetailsComponent } from 'src/app/pages/management/manage-contacts/contact-details/contact-details.component';
import { SiteDetailsComponent } from 'src/app/pages/management/manage-sites/site-details/site-details.component';
import { ManageUsersService } from '../../services/manage-users.service';
import { CalendarPopUpDialog } from 'src/app/pages/calendar/calendar.component';
import { CalendarEvent } from 'angular-calendar';
import { FileDownloadShareComponent } from 'src/app/pages/files/components/file-download-share/file-download-share.component';
import { DeleteCommomComponent } from 'src/app/pages/commonForAll/delete-commom/delete-commom.component';
import { AddFileComponent } from 'src/app/pages/files/components/add-file/add-file.component';
import { AddNotesComponent } from 'src/app/pages/files/components/add-notes/add-notes.component';
import { TranslateService } from '@ngx-translate/core';
import { ReminderViewComponent } from 'src/app/pages/Reminder/reminder-view/reminder-view.component';
import { CreateProjectComponent } from '../time-track/create-project/create-project.component';
import { TimeManagementService } from '../../services/time-management.service';
import { DatePipe } from '@angular/common';

export interface DialogData {
  screentype: any;
  eventId: any;
}

@Component({
  selector: 'pm-event-lists',
  templateUrl: './event-lists.component.html',
  styleUrls: ['./event-lists.component.scss'],
})
export class EventListsComponent implements OnInit {
  @Input() event: any;
  @Input() OtherpeplePlanningStatus!: boolean;

  events: CalendarEvent[] = [];
  durationInSeconds = 5;
  thumbsup!: number;
  groupdata: any;
  otheruserplanning :any= 'false';


  constructor(
    private calendarService: CalendarService,
    private messageService: MessageService,
    public matDialogRef: MatDialogRef<EventListsComponent>,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    
  ) {
    if(sessionStorage.getItem('lang')){
      const lang:any=sessionStorage.getItem('lang')
      this.translate.use(lang);
    }else{
      this.translate.use('English');
    }
    this.otheruserplanning = sessionStorage.getItem('otheruserplanning');
  }

  ngOnInit() {
    console.log(this.event);
  }
  deleteDialogConfirmation(data:any, type:any) {
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: type },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }

  closedialogbox() {
    this.dialog.closeAll();
  }
  openEditdEventDialog(id:any) {
    const openAddEventDialog = this.dialog.open(EditEventComponent, {
      width: '500px',
      data: { eventId: id },
    });
    openAddEventDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openEditProposalDialog(id:any) {
    const openEditProposalDialog = this.dialog.open(EditProposalComponent, {
      width: '500px',
      data: { eventId: id },
    });
    openEditProposalDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  invitedEventUpdate(id:any, evId:any) {
    console.log(id);
    this.thumbsup = evId;
    this.calendarService.invitedUserUpdateEvents(id, evId).subscribe((res) => {
      console.log(res);
      this.router.navigate(['/app/home/calendar']);
    });
    // this._snackBar.openFromComponent(PizzaPartyComponent, {
    //   duration: this.durationInSeconds * 1000,
    // });
  }

  eventProposal(id:any) {
    console.log(id);

    // delete id.color;
    sessionStorage.setItem('startdate', id.start);
    sessionStorage.setItem('eventInfo', JSON.stringify(id));
    const dialogRef = this.dialog.open(EventProposalComponent, {
      width: '900px',
      disableClose: true,
      data: { eventId: id.id },
    });
  }
  // eventdetailsShow
  // -----------------
  onClick(id:any, event:any) {
    const dialogRef = this.dialog.open(EventPopUpDialog, {
      width: '550px',
      autoFocus: false,
      disableClose: true,
      data: {
        eventId: id,
        dataevent: event,
      },
    });
  }
}

// ----------------
@Component({
  selector: 'event-popup-dialog',
  templateUrl: 'event-popup-dialog.html',
  styleUrls: ['./event-popup-dialog.scss'],
})
export class EventPopUpDialog implements OnInit {
  eventLinkedById: any;
  eventData:any;
  eventUsers:any;
  clients:any;
  eventUsersDto:any;
  eventContactsDtos:any;
  eventCreator:any;
  creator:any;
  showdata = false;
  showusers = false;
  showcontact = false;
  contactLinkedById: any;
  showfile = false;
  getNote: any;
  showNote = false;
  getfile: any;
  eventId: any;
  AdminStatus: any;
  otherpepuleplan = false;
  showReminder: boolean = false;
  remindersLinkedById: any; 
  eventLinkedByUsers: any;
  ClockInButton: boolean = true;
  CurrentTime: string;
  showClock: boolean = false;
  Mode: any;
  clockedInId: any;
  totalHoursWorked: string;
  currentTime: any;
  lastStartTime: any;
  isEndTimeMidnight: boolean = false;
  ClockInId: any;
  clockInClockOutHistory: any;
  lastendTime: any;
  TotalHoursPerDay: any;
  isEndTimeMid: boolean;

  constructor(
    public dialogRef: MatDialogRef<EventPopUpDialog>,
    private calendarService: CalendarService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private manageUsersService: ManageUsersService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cdr: ChangeDetectorRef,
    private injector: Injector,
    private datepipe: DatePipe,
    public snackBar: MatSnackBar,
  ) {
    this.AdminStatus = sessionStorage.getItem('isAdmin');
  }

  ngOnInit() {
    console.log(this.data);
    if (this.data?.screentype == 'otherUserPlanning') {
      this.otherpepuleplan = true;
    }else{
      this.otherpepuleplan = false;
    }
    this.eventCreator = this.data;

      this.findEventLinkedUsers()
    this.FindEventById();
    this.FindClockInHistory()
    this.findNode()
  }

  FindEventById(){
    this.calendarService.FindEventById(this.data.eventId).subscribe((res: any) => {
      this.eventData = res;
      this.showdata = true;
      this.eventUsers = res.eventUsers;
      this.eventUsersDto = res.eventUsersDto;
      this.eventContactsDtos = res.eventContactsDtos;
      this.clients = res.eventContacts;
    });
  }

  findNode(){
    this.injector.get(TimeManagementService).getSelectedNode().subscribe((data:any) => {
      console.log(data)
      this.Mode = data?.response[0].message
    })
  }

  FindClockInHistory(){
    this.injector.get(TimeManagementService).getAllEventClockInHistory(this.data.eventId).subscribe((result:any) => {
      console.log(result)
      this.clockInClockOutHistory = result.clockInClockOut;
      this.TotalHoursPerDay = result.totalHours
        
        // Check if there are entries in clockInClockOut
        if (this.clockInClockOutHistory.length > 0) {
            const lastEntry = this.clockInClockOutHistory[this.clockInClockOutHistory.length - 1];
            
             this.isEndTimeMid = lastEntry.endTime === "12:00 AM";
             this.lastStartTime = lastEntry.startTime;
             this.lastendTime = lastEntry.endTime;
             this.ClockInId = lastEntry.trackerId;

            console.log("Is endTime midnight:", this.isEndTimeMid);
            console.log("Last startTime:", this.lastStartTime);
        }
    })
  }

  checkout(){

    if(this.isEndTimeMid) {
      const TimeTrackDialog = this.dialog.open(CreateProjectComponent, {
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: {type: 'ClockIn', Mode: this.Mode}
      });
  
      TimeTrackDialog.afterClosed().subscribe((result) => {
        console.log(result)
        this.getCurrentTime()
        if(this.Mode == 'ClockInClockOut'){
          const currentDate: Date = new Date();
          const formattedDate: string =
            this.datepipe.transform(currentDate, 'yyyy-MM-dd') +
            'T' +
            currentDate.toTimeString().split(' ')[0];

            // const clockedInIdNumber: number = Number(this.clockedInId);
            // console.log(this.clockedInId)
          var data = [
            {
              "id": this.ClockInId,
              "endTime": formattedDate,
              "stopComments": result.comment,
              "created": formattedDate,
              "isClockIn": true,
            }
          ]
          this.injector.get(TimeManagementService).UpdateEntry(data).subscribe((data:any) => {
            if(data) {
              this.FindClockInHistory()
              const timeParts: string[] = data.totalHours.split(':');
              const hours: string = timeParts[0]; // "10"
              const minutes: string = timeParts[1]; // "01"
              const seconds: string = timeParts[2]; // "42"
              this.totalHoursWorked = `${hours}h ${minutes}m`;
              this.snackBar.open('Clocked Out Successfully','Undo', { duration: 2000 });
              
            }
          })
        }
      })
    } else {
      const TimeTrackDialog = this.dialog.open(CreateProjectComponent, {
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: {type: 'ClockOut', Mode: this.Mode}
      });
  
      TimeTrackDialog.afterClosed().subscribe((result) => {
          this.getCurrentTime()
          if(this.Mode == 'ClockInClockOut'){
            console.log(this.currentTime)
            
            const currentDate: Date = new Date();
            const formattedDate: string =
              this.datepipe.transform(currentDate, 'yyyy-MM-dd') +
              'T' +
              currentDate.toTimeString().split(' ')[0];
            var data = [
              {
                "isClockIn": true,
                "startTime": formattedDate,
                "startComments": result.comment,
                "projectId": result.project,
                "mode": 1,
                "eventId": this.data.eventId
              }
            ]
            this.injector.get(TimeManagementService).CreateEntry(data).subscribe((data:any) => {
              if(data) {
                
                this.clockedInId = data.response[0].message
                console.log(this.clockedInId)
                this.snackBar.open('Clocked In Successfully','Undo', { duration: 2000 });
                this.FindClockInHistory()
              }
            })
          }
      })
    }
  }

  duration(totalDuration: string){
    const [hours, minutes] = totalDuration?.split(':');
    return `${+hours}h ${minutes}m `;
  }

  getCurrentTime(): any {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12; // If hours is 0, set it to 12

    // Pad minutes to always show two digits
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Return the formatted time
    this.CurrentTime = `${hours}:${formattedMinutes} ${period}`;
    console.log(this.CurrentTime)
}

  // findEventLinked
  findEventLinked() {
    this.calendarService.FindEventLinked(this.data.eventId).subscribe((res) => {
      this.eventLinkedById = res;
      this.showusers = !this.showusers;
    });
  }

  findEventLinkedUsers() {
    this.calendarService.FindEventLinked(this.data.eventId).subscribe((res) => {
      this.eventLinkedByUsers = res;
      console.log(this.eventLinkedByUsers)
    });
  }
  // findcontactLinked
  findcontactLinked() {
    this.calendarService.FindEventLinked(this.data.eventId).subscribe((res) => {
      this.contactLinkedById = res;
      this.showcontact = !this.showcontact;
    });
  }


  findRemindersLinked() {
    this.calendarService.FindReminderLinked(this.data.eventId, 2).subscribe((res) => {
      this.remindersLinkedById = res;
      console.log(this.remindersLinkedById)
      this.showReminder = !this.showReminder;
    });
  }
  // openUserDialog
  openUserDialog(details:any, type: string): void {
    const dialogRef = this.dialog.open(UserDetailsComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: { data: details, type: type },
    });
  }
  deleteDialogConfirmation(data:any, type:any) {
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: { data, type: type },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      this.dialogRef.close(true);
    });
  }

  // openEditdEventDialog
  openEditdEventDialog(id:any) {
    const openAddEventDialog = this.dialog.open(EditEventComponent, {
      width: '500px',
      data: { eventId: id },
    });
    openAddEventDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  // openContactDialog
  openContactDialog(row:any, type: string): void {
    this.dialog.open(ContactDetailsComponent, {
      data: { data: row, type: type },
      width: '500px',
    });
  }
  // openSiteDialog
  openSiteDialog(id: number, type: string): void {
    this.dialog.open(SiteDetailsComponent, {
      data: { id: id, type: type },
      width: '500px',
    });
  }

  // reminderDialog
  // openContactDialog
  openReminderDialog(row:any, type: string, id:any): void {
    const updateReminder = this.dialog.open(ReminderViewComponent, {
      data: { data: row, type: type,  selectedUsers: this.eventLinkedByUsers, eventId: id},
      width: '500px',
    });
    updateReminder.afterClosed().subscribe((result) => {
      if(result) {
        this.FindEventById()
      }
    
    });
    
  }
  // findfilesLinked
  findfilesLinked(count:any) {
    if (count > 0) {
      this.manageUsersService
        .getfile(this.eventData.id, 'Events')
        .subscribe((res) => {
          this.getfile = res;
          this.showfile = !this.showfile;
        });
    }
  }
  // findnotesLinked
  findnotesLinked(count:any) {
    if (count > 0) {
      this.manageUsersService
        .getnotes(this.eventData.id, 'Events')
        .subscribe((res) => {
          this.getNote = res;
          this.showNote = !this.showNote;
        });
    }
  }
  // gotofiles
  gotofiles(row:any) {
    this.dialog.open(FileDownloadShareComponent, {
      width: '500px',
      data: row,
      position: { top: '125px', left: '700px' },
      autoFocus: false,
      disableClose: true,
    });
  }

  // AddFile
  AddFile(row:any) {
    const addfile = this.dialog.open(AddFileComponent, {
      disableClose: true,
      data: {
        data: row,
        attachmentLevel: 'Events',
        ismanage: true,
      },
      width: '500px',
      position: { top: '125px', left: '700px' },
      panelClass: ['addFiles'],
    });
    addfile.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
  //  AddNote
  AddNote(row:any) {
    const addNote = this.dialog.open(AddNotesComponent, {
      disableClose: true,
      data: {
        data: row,
        attachmentLevel: 'Events',
        ismanage: true,
      },
      position: { top: '125px', left: '700px' },
      width: '500px',
      panelClass: ['addNotes'],
    });
    addNote.afterClosed().subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
}
