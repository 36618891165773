<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title *ngIf="screentype=='Composemail'" class="m-0 dialog-title text-center w-100">
        New Message 
    </h2>  
    <h2 mat-dialog-title *ngIf="screentype=='Replyemail'||screentype=='ReplyAllemail'" class="m-0 dialog-title text-center w-100">
    Reply Message 
</h2>
<h2 mat-dialog-title *ngIf="screentype=='groupUserDetails'" class="m-0 dialog-title text-center w-100">
    Email Read Receipt
</h2>  
<h2 mat-dialog-title *ngIf="screentype=='EventDetails'" class="m-0 dialog-title text-center w-100">
    Event Created
</h2> 
<h2 mat-dialog-title *ngIf="screentype=='TaskDetails'" class="m-0 dialog-title text-center w-100">
    Task Created
</h2>  
<h2 mat-dialog-title *ngIf="screentype=='Forwardemail'"
class="m-0 dialog-title text-center w-100">
Forward Message 
</h2>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content class="mr-0 ml-0">
    <!-- Composemail  -->
    <ng-container *ngIf="screentype=='Composemail'">
        <div class="row mt-2 mb-2">
            <mat-form-field class="w-100 custom-form-field">
                <mat-label style="cursor: pointer;">To</mat-label>
                <input matInput [(ngModel)]="to">
            </mat-form-field>
            <mat-form-field class="w-100 custom-form-field">
                <mat-label style="cursor: pointer;">Cc</mat-label>
                <input matInput [(ngModel)]="Cc">
            </mat-form-field>
            <mat-form-field class="w-100 custom-form-field">
                <mat-label style="cursor: pointer;">Bcc</mat-label>
                <input matInput [(ngModel)]="Bcc">
            </mat-form-field>
        </div>
        <div class="row mt-2 mb-2">
            <mat-form-field class="w-100 custom-form-field">
                <mat-label>Subject</mat-label>
                <input matInput [(ngModel)]="Subject">
            </mat-form-field>
        </div>
        <div class="row mt-2 mb-2">
            <form [formGroup]="BodyForm">
            <!-- body   -->
              <ckeditor formControlName="bodycontent"></ckeditor>
              <!-- body end  -->
               </form>
        </div>
        <div class="row mb-4 justify-content-end">
            <div class="col-4">
                <button mat-raised-button  class="b-blue"  (click)="onSend()">Send</button>
            </div>
        </div>
    </ng-container>
        <!-- Replyemail  --> 
        <ng-container *ngIf="screentype=='Replyemail'||screentype=='ReplyAllemail'">
            <div class="row mt-2 mb-2">
                <mat-form-field class="w-100 custom-form-field">
                    <mat-label style="cursor: pointer;">To</mat-label>
                    <input matInput [readonly]="readonly" [(ngModel)]="to">
                </mat-form-field>
            </div>
            <div class="row mt-2 mb-2">
                <form [formGroup]="BodyForm">
                    <!-- body   -->
                      <ckeditor formControlName="bodycontent"></ckeditor>
                      <!-- body end  -->
                       </form>
            </div>
            <div class="row mb-4 justify-content-end">
                <div class="col-4">
                    <button mat-raised-button  class="b-blue"  (click)="onSend()">Send</button>
                </div>
            </div>
        </ng-container>
            <!-- Forwardemail  -->
    <ng-container *ngIf="screentype=='Forwardemail'">
        <div class="row mt-2 mb-2">
            <mat-form-field class="w-100 custom-form-field">
                <mat-label>To</mat-label>
                <input matInput [(ngModel)]="to">
            </mat-form-field> 
        </div>
        <div class="row mb-4 justify-content-end">
            <div class="col-4">
                <button mat-raised-button  class="b-blue"  (click)="onSend()">Send</button>
            </div>
        </div>
    </ng-container>


    <!-- show user Details  -->
    <ng-container *ngIf="screentype=='groupUserDetails'">
        <div class="row justify-content-center">
            <div class="col-2">
                <div>
                    <img  style="width: 40px;height: 40px;border-radius: 50%;object-fit: cover;"  *ngIf="user?.profile!=''&&user?.profile!=null" onError="src='assets/avatar.svg'" 
                    [src]="user?.profile ? user?.profile:'assets/avatar.svg'">
                    <div *ngIf="user?.profile==''||user?.profile==null"  class="profile_JG">{{getuserFLnames(user?.userName)}}</div>
                </div> 
            </div>
        </div>
        <div class="row mt-2 mb-2">
            <h4 style="text-align: center;width: 100%;font-weight: 900;">{{user?.userName}}</h4>
            <h4 style="text-align: center;width: 100%;">
                <span>
                    <img  src="../../../assets/images/iconsForsvg/done_all.svg" alt="">
                    </span>
                <span class="ml-3 fw-600 color_grey"> {{user?.viewdDateTime| date:'dd-MMM-yyyy H:mm'}}</span>
            </h4>
        </div>
    </ng-container>


    <!-- Event_Task_Details  -->
    <ng-container *ngIf="screentype=='TaskDetails'||screentype=='EventDetails'">
        <div class="row  mt-2 justify-content-center">
           <div class="col-6">
            <div style="display: flex;">
                <div>
                    <img class="sidemenuicons" (click)="openEvents()" *ngIf="screentype=='EventDetails'"  src="../../../assets/images/iconsForsvg/calendar.svg"  >
                    <img class="sidemenuicons" (click)="opentaskDialog()" *ngIf="screentype=='TaskDetails'"   src="../../../assets/images/iconsForsvg/task.svg"  >
                </div> 
                <div>
                    <p style="position: relative;top:8px;left: 5px;">{{eventtaskdetails?.title}}</p>
                </div>
            </div>
           </div>
        </div>
        <div class="row p-0 justify-content-center">
            <div class="col-8 p-0">
               <div style="display: flex;">
                <div>
                    <p style="text-align: center;position: relative;top: 12px;right: 2px;color: black;font-weight: 900;">
                        Creator : 
                    </p>
                </div>
                <div>
                    <img  style="width: 40px;height: 40px;border-radius: 50%;object-fit: cover;"  *ngIf="eventtaskdetails?.profile!=''&&eventtaskdetails?.profile!=null" onError="src='assets/avatar.svg'" 
                    [src]="eventtaskdetails?.profile ? eventtaskdetails?.profile:'assets/avatar.svg'">
                    <div *ngIf="eventtaskdetails?.profile==''||eventtaskdetails?.profile==null"  class="profile_JG">{{getuserFLnames(eventtaskdetails?.firstName+''+eventtaskdetails?.lastName)}}</div>
                </div>
                <div>
                    <p style="text-align: center;    position: relative;top: 12px;left: 10px;">{{eventtaskdetails?.firstName+' '+eventtaskdetails?.lastName}}</p>
                </div>
               </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-10">
                <h4 style="text-align: center;width: 100%;">
                    <span style="font-weight: 900;"> Created on : </span>
                    <span>
                        <mat-icon class="ml-3">transform</mat-icon>
                    </span>
                    <span class="ml-3 fw-600 color_grey"> {{eventtaskdetails?.convertedDateTime| date:'dd-MMM-yyyy H:mm'}}</span>
                </h4>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
