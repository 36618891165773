import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmailSettingComponent } from './email-setting/email-setting.component';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { SyncOptionsListComponent } from 'src/app/core/components/sync-options-list/sync-options-list.component';
import { EmailPopupsComponent } from '../dashboard/email-popups/email-popups.component';
import { CreateTaskComponent } from '../management/manage-tasks/create-task/create-task.component';
import { CreateEventComponent } from '../calendar/create-event/create-event.component';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver'; 
import { EventPopUpDialog } from 'src/app/core/components/event-lists/event-lists.component';
import { ViewTaskComponent } from '../management/manage-tasks/view-task/view-task.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ResizeService } from 'src/app/core/services/resize.service';

@Component({
  selector: 'app-emaildashboard',
  templateUrl: './emaildashboard.component.html',
  styleUrls: ['./emaildashboard.component.scss']
})
export class EmaildashboardComponent implements OnInit {
  @ViewChild('myIframe') myIframe!: ElementRef;
  mailids=[]
  date=new Date()
  dropdownstatus='group'
  allmails=[]
  mailbody=null
  sidemenusection='Inbox'
  SyncAccDetails:any;
  SyncAccDetails1:any;
  seletedmails=[]
  color = 'primary';
  mode = 'indeterminate';
  value = 10;
  spinner=false
  mailLength=100;
  categorieslist=[]
  Adminstatus=false;
  MailsSettings=false
  AccountForm: FormGroup;
  isMobileView!: boolean;
  mailbodyview:boolean=false;
  size: any;
  width!: number;
  constructor(
    private matDialogRef: MatDialogRef<EmaildashboardComponent>,
    private dialog: MatDialog,
    private GoogleApiService:GoogleApiService,
    private injector: Injector,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
  ) { 
    this.AccountForm = this.formBuilder.group({
      AccountList: [],
    });
  }
  ngOnInit(): void {
    this.injector.get(ResizeService).size$.subscribe((user) => {
      this.size = user.size;
      if (this.size < 992) {
        this.isMobileView = true;
      }else{
        this.isMobileView = false;
      }
    });
    this.width = window.innerWidth;
    if (!this.size && this.width < 992) {
      this.isMobileView = true;
    } else if (!this.size && this.width > 992) {
      this.isMobileView = false;
    }
    this.Adminstatus = sessionStorage.getItem('isAdmin')=='true'?true:false;
    this.MailsSettings=sessionStorage.getItem('MailsSettings')=='true'?true:false
    if(this.allmails.length==0){
       this.spinner=true;
    }else{
       this.spinner=false;
    }
    this.getallCategory()
    this.GetSyncAccDetails()
    this.getmailapicall(
    {
      "label": this.sidemenusection.toUpperCase(),
      "maxResults": 10,
    }
  )
  }

  getallCategory(){
    this.injector.get(ManageUsersService).GetAllCategories().subscribe((result:any)=>{
      if(result){
        this.categorieslist=result
      }
    })
  }

  GetSyncAccDetails(){
    this.spinner=true
    this.GoogleApiService.GetAllSynchMailAccounts().subscribe((result:any)=>{
      if(result){
        this.spinner=false
        this.mailids=result
        console.log(result)
        this.AccountForm.get('AccountList').setValue([result[0]])
        this.seletedmails.push(result[0])
      }
    },error=>{
      this.spinner=false
    })
  } 



  gethoverclass(x){
    if(this.mailbody){
      if(x.id==this.mailbody.id){
        return 'hoverclass'
      }else if(!x.isUnReaded){
        return 'Readedclass'
      }
    }
  }

  selectChange(event){
    console.log(event)
    console.log(event.value)
    this.seletedmails=event.value
    this.getmails(10,'')
  }
  getfiletype(name:any){
    if(name!=null){
    return name.split('.')[1]
    }else{
      return ' No Name'
    }
  }

  getmails(maxResults,pageToken){
    var body:any
    body=        {
      "label": this.sidemenusection.toUpperCase(),
      "maxResults": maxResults,
      "selectedAccounts": []
    }
    this.seletedmails.forEach(element => {
      body.selectedAccounts.push(
        {
          "id": element.id,
          "pageToken": pageToken,
          "isCollabrative": element.isCollabrative,
          "isGmail": element.isGmail
        }
      )
    });
    this.getmailapicall(body)
  }

  getmailapicall(body){
    this.GoogleApiService.GetAllMails(body).subscribe((result:any)=>{
      if(result){
        this.spinner=false
        this.allmails=result.emails
        if(result.emails.length!=0){
          if(this.isMobileView==false){
            this.getmailbody(result?.emails[0])
          }
        }
      }
    },error=>{
      this.spinner=false
    })
  }

  siddemenu(value){
    if(this.sidemenusection==value){
      return 'siddemenucolor'
    }else{
      return 'siddemenu'
    }
  } 

  sidemenuclick(value){
    this.sidemenusection=value
    this.mailbody=null
    if(this.seletedmails.length!=0){
      this.getmails(10,'')
    }else{
      this.getmailapicall(
        {
          "label": this.sidemenusection.toUpperCase(),
          "maxResults": 10,
        }
      )
    }
  }

  CreateTask(x){
    const CreateTask = this.dialog.open(CreateTaskComponent, {
      width: '500px',
      autoFocus: false,
      data: {data: x, type: 'Createtask_with_mail' },
      disableClose: true,
    });

    CreateTask.afterClosed().subscribe((result) => {
      if(result){
        this.mailbody=null
        this.getmails(10,'')
      }
    });
  }
  CreateEvent(x){
    const CreateTask = this.dialog.open(CreateEventComponent, {
      width: '500px',
      autoFocus: false,
      data: {data:x, screenType: 'CreateEvent_with_mail' },
      disableClose: true,
    });

    CreateTask.afterClosed().subscribe((result) => {
        if(result){
          this.mailbody=null
          this.getmails(10,'')
        }
    });
  }

  nextpage(e: any) {

  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  }


  clickingsetting(){
    const emailsetting = this.dialog.open(EmailSettingComponent, {
      width: '99%',
      autoFocus: false,
      disableClose: true,
    });

    emailsetting.afterClosed().subscribe((result) => {

    });
  }



  getmailbody(value){
    this.GoogleApiService.GetMailDtlById(value?.accountId,this.sidemenusection.toLocaleUpperCase(),value?.id,value?.isGmail).subscribe((result:any)=>{
      if(result){
        this.mailbody=result
        // this.downloadHtmlFile(result?.body?.content)
      }
    })
    if(this.isMobileView){
      this.mailbodyview=true;
      this.isMobileView=false;

    }
  }

  ConverttoContact(value){
    console.log(value)
    this.GoogleApiService.CreateContactFromMail(value?.accountId,value?.id,value?.isGmail).subscribe((result)=>{
      if(result){
        console.log(result)
        this.snackBar.open('Create Contact From Mail done', '', { duration: 2000, })
      }
    },error=>{
      if(error.status==200){
        this.snackBar.open('Create Contact From Mail done', '', { duration: 2000, })
      }
    })
  } 

  syncaccount(){
    this.dialog.open(SyncOptionsListComponent, {
      data:{screentype:'twooptions'},
      autoFocus: false,
      disableClose: true,
    });
  }

  Composemail(){
    console.log(this.seletedmails)
    if(this.seletedmails.length!=0){
      this.dialog.open(EmailPopupsComponent, {
        width: '600px',
        data:{seletedmails:this.seletedmails,screentype:'Composemail'},
        autoFocus: false,
        disableClose: true,
      });
    }
  }
  Replyemail(data){
    this.EmailPopup(data,'Replyemail','600px');
    
  } 
  ReplyAllemail(data){
    this.EmailPopup(data,'ReplyAllemail','600px')
  } 
  Forwardemail(data){
    this.EmailPopup(data,'Forwardemail','600px')
  }

  showdropdownstatus(value){
    this.dropdownstatus=value
  }

  lablecolor(value){
    if(value?.isSocial){
     return 'Socialstyle'
    }else if(value?.isPromotions){
      return 'Promotionalstyle'
    }
    // else if(value?.isUnReaded){
    //   return 'Healthstyle'
    // }
  }
  lablename(value){
    if(value?.isSocial){
     return 'Social'
    }else if(value?.isPromotions){
      return 'Promotion'
    }
  }

  closepopup(){
    this.matDialogRef.close()
  }


downloadHtmlFile(htmlContent) {
        const blob = new Blob([htmlContent], { type: 'text/html' });
        saveAs(blob, 'body.html');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'sample.html';

        // Programmatically click the link to trigger the download
        document.body.appendChild(link); // Append link to body
        link.click();
}



       showevent(data){
        console.log(data)
        this.GoogleApiService.FindEventConvertorFromMail(data.eventId).subscribe((result)=>{
          if(result){
            console.log(result)
         this.EmailPopup(result,'EventDetails','500px')
          }
        })
       }
       showtask(data){
        console.log(data)
        this.GoogleApiService.FindTaskConvertorFromMail(data.taskId).subscribe((result)=>{
          if(result){
            console.log(result)
         this.EmailPopup(result,'TaskDetails','500px')
          }
        })
       }
       showgroupinUser(data){
        console.log(data)
         this.EmailPopup(data,'groupUserDetails','500px')
       }

       EmailPopup(data: any,screentype,width) {
        this.dialog.open(EmailPopupsComponent, {
          width: width,
          data:{maildata:data,screentype:screentype},
          autoFocus: false,
          disableClose: true,
        });
      }




}
