import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import imageCompression from 'browser-image-compression';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {

  constructor() { }



  async compressImage(file: File): Promise<Blob> {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error during image compression:', error);
      throw error;
    }
  }
}
