import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventPopUpDialog } from 'src/app/core/components/event-lists/event-lists.component';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { ViewTaskComponent } from '../../management/manage-tasks/view-task/view-task.component';

@Component({
  selector: 'app-email-popups',
  templateUrl: './email-popups.component.html',
  styleUrls: ['./email-popups.component.scss']
})
export class EmailPopupsComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinner=false;
  screentype=''
   to = ''
   Subject = ''
   Cc=null
   Bcc=null
  seletedmails:any=[]
  readonly=false
  BodyForm: FormGroup;
  user:any;
  eventtaskdetails:any;
  date=new Date()
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<EmailPopupsComponent>,
    private GoogleApiService:GoogleApiService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private injector: Injector,
    private dialog: MatDialog,
  ) { 
    this.BodyForm = this.formBuilder.group({
      bodycontent: [''],
    });
  }

  ngOnInit(): void {
    console.log(this.data)
    this.seletedmails=this.data?.seletedmails
    this.screentype=this.data.screentype
    if(this.screentype=='Replyemail'||this.screentype=='ReplyAllemail'){
      this.to=this.data?.maildata?.senderEmail?this.data?.maildata?.senderEmail:this.data?.maildata?.fromEmail
      this.Subject=this.data?.maildata?.subject
      this.readonly=true
    }else  if(this.screentype=='Forwardemail'){
      this.BodyForm.get('bodycontent').setValue(this.data?.maildata?.body?.content)
    }else  if(this.screentype=='groupUserDetails'){
      this.user=this.data.maildata
    }else  if(this.screentype=='TaskDetails'||this.screentype=='EventDetails'){
      this.eventtaskdetails=this.data.maildata
    }

  }
  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 

  onSend(){
    console.log(this.BodyForm)
    if(this.screentype=='Composemail'){
      const formData = new FormData();
      formData.append('AccountId', this.data?.seletedmails[0].id);
      formData.append('IsGmail', this.data?.seletedmails[0]?.isGmail);
      formData.append('ToEmail', this.to);
      formData.append('subject', this.Subject);
      formData.append('body', this.BodyForm.value?.bodycontent);
      if(this.Cc!=null){
        formData.append('ccEmail', this.Cc);
      }
      if(this.Bcc!=null){
        formData.append('bccEmail', this.Bcc);
      }
      this.GoogleApiService.SendMail(formData).subscribe((result)=>{
        if(result){
          this._snackBar.open('Mail sent sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      },error=>{
        if(error.status==200){
          this._snackBar.open('Mail sent sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      })

    }else if(this.screentype=='Replyemail'||this.screentype=='ReplyAllemail'){
      const formData = new FormData();
      formData.append('AccountId', this.data?.maildata.accountId);
      formData.append('IsGmail', this.data?.maildata?.isGmail);
      formData.append('ToEmail', this.to);
      formData.append('subject', this.Subject);
      formData.append('body', this.BodyForm.value?.bodycontent);
      if(this.Cc!=null){
        formData.append('ccEmail', this.Cc);
      }
      if(this.Bcc!=null){
        formData.append('bccEmail', this.Bcc);
      }
      this.GoogleApiService.ReplyMail(this.data?.maildata?.id,this.screentype=='ReplyAllemail'?true:false,formData).subscribe((result)=>{
        if(result){
          console.log(result)
          this._snackBar.open('Reply email sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      },error=>{
        if(error.status==200){
          this._snackBar.open('Reply email sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      })

    }else if(this.screentype=='Forwardemail'){
      const formData = new FormData();
      formData.append('AccountId', this.data?.maildata.accountId);
      formData.append('IsGmail', this.data?.maildata?.isGmail);
      formData.append('ToEmail', this.to);
      formData.append('subject', this.Subject);
      formData.append('body', this.BodyForm.value?.bodycontent);
      if(this.Cc!=null){
        formData.append('ccEmail', this.Cc);
      }
      if(this.Bcc!=null){
        formData.append('bccEmail', this.Bcc);
      }

      this.GoogleApiService.ForwardMail(this.data?.maildata?.id,formData).subscribe((result)=>{
        if(result){
          console.log(result)
          this._snackBar.open('Forward email sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()
        }
      },error=>{
        if(error.status==200){
          this._snackBar.open('Forward email sucessfully', '', { duration: 2000 });
          this.matDialogRef.close()

        }
      })
    }

  }


  openEvents() {
    const dialogRef = this.dialog.open(EventPopUpDialog, {
      width: '550px',
      autoFocus: false,
      disableClose: true,
      data: {
        eventId: this.data.maildata.eventId,
        dataevent: this.data.maildata,
        screentype: this?.data?.screenType ? this?.data?.screenType : null,
      },
    });
  }
  opentaskDialog() {
    const opentaskDialog= this.dialog.open(ViewTaskComponent, {
       width: '500px',
       autoFocus: false,
       disableClose: true,
       data: this.data.maildata.taskId
     });
     opentaskDialog.afterClosed().subscribe((result: any) => {
       if (result) {

       }
     })
   }

}
