import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProfilePickFormatService } from 'src/app/core/services/profile-pick-format.service';
import { CreateCategoryComponent } from '../create-category/create-category.component';
import { CategoryDetailsComponent } from '../category-details/category-details.component';
import { ManageUsersService } from 'src/app/core/services/manage-users.service';
import { GoogleApiService } from 'src/app/core/services/SYNC/google-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupCommonComponent } from '../../commonForAll/popup-common/popup-common.component';
import { DeleteCommomComponent } from '../../commonForAll/delete-commom/delete-commom.component';

@Component({
  selector: 'app-email-setting',
  templateUrl: './email-setting.component.html',
  styleUrls: ['./email-setting.component.scss']
})
export class EmailSettingComponent implements OnInit {
  collaborationlist=[]
  AccesspermissionUsers=[]
  categorieslist=[]
  sidemenusection=''
  showsettingscreen=true
  allmails=[]
  CollaboratedUsersCategories=[];
  seletedUserindex
  UpdateAccountAsColabrative=false;
  filterforuserlist: any;
  applybuttonstatus=false
  onToggleChangeuserdetails: any;
  constructor(
    private matDialogRef: MatDialogRef<EmailSettingComponent>,
    private injector: Injector,
    private GoogleApiService:GoogleApiService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { 

  }

  ngOnInit(): void {
    this.getallCategory() 
    this.GetSyncAccDetails()
        // get all users
        this.injector.get(ManageUsersService).findAllUsersDropdown().subscribe((res: any) => {
          console.log(res);
          const data = res;
          data.forEach(element => {
            element.checked=false;
          });
          this.filterforuserlist = data;
        }); 
  }

  applybutton(){
    if(this.applybuttonstatus==false){
      this.applybuttonstatus=true
    }

  }

  GetSyncAccDetails(){
    this.GoogleApiService.GetAllSynchMailAccounts().subscribe((result:any)=>{
      if(result){
        this.collaborationlist=result
        this.allmails=[]
        result.forEach(element => {
          if(element.isCollabrative==true){
            this.allmails.push(element)
          }
        });
      }
    })
  }

  onToggleChange(event,details){
    console.log(event.checked)
    console.log(details)
    this.onToggleChangeuserdetails=details
    const CollaborationDialog = this.dialog.open(PopupCommonComponent, {
      data: { data:details, screenType: event.checked==true?'Collaboration_Add':'Collaboration_Remove' },
      disableClose: true,
      width: '400px',
    });
    CollaborationDialog.afterClosed().subscribe((result) => {
      if(result==true){
        this.UpdateAccountAsColabrative=true
      }
      if(event.checked==false){
        this.GoogleApiService.UpdateAccountAsColabrative(details.id,event.checked,result).subscribe((result)=>{
          if(result){
            this.snackBar.open('Update Collaboration done', '', { duration: 2000, });
            this.GetSyncAccDetails()
          }
        },error=>{
          if(error.status=200){
            this.snackBar.open('Update Collaboration done ', '', { duration: 2000, });
            this.GetSyncAccDetails()
          }else{
            this.snackBar.open('Update Collaboration failed', '', { duration: 2000, });
          }
        })
      }
    })
  }

  onSubmiaccess(){
    let selectedUsers=[]
    this.filterforuserlist.forEach(element => {
      if(element.checked==true){
        selectedUsers.push(element.id)
      }
    });
    this.GoogleApiService.UpdateAccountAsColabrative(this.onToggleChangeuserdetails.id,true,selectedUsers.toString()).subscribe((result)=>{
      if(result){
        this.snackBar.open('Update Collaboration done', '', { duration: 2000, });
        this.GetSyncAccDetails()
      }
    },error=>{
      if(error.status=200){
        this.snackBar.open('Update Collaboration done', '', { duration: 2000, });
        this.GetSyncAccDetails()
      }else{
        this.snackBar.open('Update Collaboration failed', '', { duration: 2000, });
      }
    })
  }

  onToggleChange2(event,details,index){
    this.seletedUserindex=index
    console.log(event.checked)
    console.log(details)
    if(event.checked){
      this.GoogleApiService.GetCollaboratedUsersCategories(details.id).subscribe((result:any)=>{
        if(result){
          this.CollaboratedUsersCategories=result
          console.log(result)
        }
      })

    }

  }

  getallCategory(){
    this.injector.get(ManageUsersService).GetAllCategories().subscribe((result:any)=>{
      if(result){
        this.categorieslist=result
      }
    })
  }

  siddemenu(value){
    if(this.sidemenusection==value.email){
      return 'siddemenucolor'
    }else{
      return 'siddemenu'
    }
  }
  sidemenuclick(value){
    this.sidemenusection=value.email
    this.injector.get(ManageUsersService).FindCollaborativeUserOftheAccount(value.id).subscribe((result:any)=>{
      if(result){
        this.AccesspermissionUsers=result
      }
    })
  }

  getuserFLnames(value:any){
    return this.injector.get(ProfilePickFormatService).getuserFLnames(value)
  }
  getColor(index:number) {
    return this.injector.get(ProfilePickFormatService).getColor(index)
  } 
  
 
  closepopup(){
      this.matDialogRef.close()
  }

  addCategory(){
    const CreateCategoryDialog = this.dialog.open(CreateCategoryComponent, {
      data: { screenType: 'CreateCategory' },
      disableClose: true,
      width: '500px',
    });
    CreateCategoryDialog.afterClosed().subscribe((result) => {
      this.getallCategory()
    });
  }

  Categorydetails(details){
    const EditCategoryDialog = this.dialog.open(CategoryDetailsComponent, {
      data: {details:details, screenType: 'Categorydetails' },
      disableClose: true,
      width: '500px',
    });
    EditCategoryDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  Categoryedit(details){
    const EditCategoryDialog = this.dialog.open(CreateCategoryComponent, {
      data: {details:details, screenType: 'EditCategory' },
      disableClose: true,
      width: '500px',
    });
    EditCategoryDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  Categorydelete(details){
    const deleteorder = this.dialog.open(DeleteCommomComponent, {
      data: { details, type: 'Categorydelete' },
    });
    deleteorder.afterClosed().subscribe((result: any) => {
      this.getallCategory() 
    });


  }




}
