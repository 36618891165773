import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManageAutomationService } from 'src/app/core/services/manage-automation.service';

@Component({
  selector: 'app-accordian-view',
  templateUrl: './accordian-view.component.html',
  styleUrls: ['./accordian-view.component.scss']
})
export class AccordianViewComponent implements OnInit {

  itemsList: string[] = ['FirstName', 'LastName', 'Gender', 'Position', 'PhoneNumber', 'Email', 'Address', 'City', 'Country', 'ZipCode', 'Created'];
  EntityName: string = '';
  ColoumnList: Object;

  private entityMapping: { [key: string]: string } = {
    'Create User': 'User',
    'Create Contact': 'Contact',
    'Create Site': 'Site',
    'Create Product': 'Product',
    'Create Transaction': 'Transaction',
    'Create Order': 'Order',
    'Create Task': 'Task',
    'Create Initiative': 'Initiative'
  };

  constructor(
    public dialogRef: MatDialogRef<AccordianViewComponent>,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.setEntityName();
   }

  ngOnInit(): void {
    console.log(this.data)
    
    this.setEntityName()
    this.getColoumns()
  }

  setEntityName() {
    this.EntityName = this.entityMapping[this.data.ParentEntity] || this.entityMapping[this.data.beforeParentEntity] || '';
  }

  getColoumns(){
    this.injector.get(ManageAutomationService).getEntityColoumns(this.EntityName).subscribe((data) => {
      if (data) {
        this.ColoumnList = data
        console.log(data);
      }
    });
  }

  selectItem(item: string): void {
    this.dialogRef.close(this.EntityName + '.' + item);
  }

}
