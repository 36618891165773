import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
const baseUrl = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class AutomationService {

  constructor(private http: HttpClient) { }


  loginToken = sessionStorage.getItem('access_token');


  getGoogleCalender() {
    return this.http.get(baseUrl + '/api/OAuth/GoogleOAuthRedirect');
  }

  findConnector() {
    return this.http.get(baseUrl + '/api/OAuth/FindConnectors');
  }


  CreateScenario(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.post(baseUrl + '/api/Automation/CreateScenario', requestData, httpOptions);
  }
  CreateScenarioConditions(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.post(baseUrl + '/api/Automation/CreateScenarioConditions', requestData, httpOptions);
  }
  CreateScenarioActions(requestData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) };
    return this.http.post(baseUrl + '/api/Automation/CreateScenarioActions', requestData, httpOptions);
  }

  FindEntity(){
    return this.http.get(baseUrl + '/api/Automation/FindScenarioEvents');
  }

  FindColumns(Entity:any){
    return this.http.get(baseUrl + '/api/Automation/FindColumns?Entity=' + Entity)
  }

  getScenrios(pageno:any, numberofRecord:any, search:any){
    return this.http.get(baseUrl + '/api/Automation/FindAllScenarios?PageNumber=' + pageno + '&NumberOfRecord=' + numberofRecord + '&Search=' + search)
  }

  getScnerioByID(id:any) {
    return this.http.get(baseUrl + '/api/Automation/FindScenarioById?Id=' + id)
  }

  getUsersByID(id:any) {
    return this.http.get(baseUrl + '/api/Automation/FindScenarioUsersById?Id=' + id)
  }

  deleteScenerioById(id: string) {
    return this.http.delete(baseUrl + '/api/Automation/DeleteScenario?Id=' + id).toPromise();
  }

  getExecutionsWithId(id:string, pageno:any, numberofRecord:any, search:any){
    return this.http.get(baseUrl + '/api/Automation/TrackAutomation?ScenarioId=' + id + '&PageNumber=' + pageno + '&NumberOfRecord=' + numberofRecord + '&Search=' + search)
  }
}
