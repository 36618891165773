import { Component, Inject, Injector, OnInit } from '@angular/core';
import { ChooseTaskOptionComponent } from '../create-initiatives/choose-task-option/choose-task-option.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeManagementService } from 'src/app/core/services/time-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-conformation',
  templateUrl: './conformation.component.html',
  styleUrls: ['./conformation.component.scss']
})
export class ConformationComponent implements OnInit {
screentype=''
form: FormGroup;
todaydate = new Date();
deleteType='1'
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ChooseTaskOptionComponent>,
    private injector: Injector,
    private snackBar: MatSnackBar
  ) {

    this.form = this.formBuilder.group({
      StartDate: [this.todaydate, [Validators.required]],
      EndDate: ['', [Validators.required]],
    })
   }

  ngOnInit(): void {
    console.log(this.data)
    this.screentype=this.data.type
  }

  onRadioChange(event:any){
    console.log(event)
    this.deleteType=event.value
  }


  onSubmit(value:any){
   this.dialogRef.close(value)
  }

  onSubmit1(){
    this.dialogRef.close(this.form.value)
   }
   onSubmit2(){
    this.dialogRef.close(this.deleteType)
   }

   onSubmitTrack(value:any){
    this.dialogRef.close(value)
   }

   DeleteProject(){
    this.injector.get(TimeManagementService).deleteProject(this.data.data.id).subscribe((data:any) => {
      if(data){
        this.dialogRef.close(true)
        this.snackBar.open(data?.response[0].message,'Undo', { duration: 2000 });
      }
    })
   
   }

   Approve(){
    this.dialogRef.close(true)
   }

   ApproveTimeSheet(){
    const data = {
      "timeEntryIds": [this.data.data],
      "status": 1
    }
    this.injector.get(TimeManagementService).approveTimeEntry(data).subscribe(result => {
      if(result) {
        this.dialogRef.close(true)
      }
    })
   }

   DenyTimeSheet(){
    const data = {
      "timeEntryIds": [this.data.data],
      "status": 2
    }
    this.injector.get(TimeManagementService).approveTimeEntry(data).subscribe(result => {
      if(result) {
        this.dialogRef.close(true)
      }
    })
   }

}
