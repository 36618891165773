<div class="User-details">
  <div class="d-flex flex-row align-items-center modal-header1">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
      {{'Activity Log'|translate}}
    </h2>
    <mat-icon (click)="opensearch()" class="mx-2">filter_list</mat-icon>
    <mat-icon (click)="closeDialog()" class="mx-2">close</mat-icon>
  </div>

  <mat-dialog-content class="mr-0 ml-0">
    
  <!-- body  -->
   <ng-container *ngIf="ActivitLogdata.length !=0">
    <div class="mt-4" style="display: flex;" >
      <div style="display: flex; width: 50%;">
       <div >
        <!-- <h2 style="margin-right: 3px;"> Activity Log For </h2> -->
      <img class="avatar" style="border-radius: 50%;"*ngIf="DialogData?.profilePicture" [src]="DialogData?.profilePicture">
      <div *ngIf="((DialogData?.profilePicture == '' || DialogData?.profilePicture == null) && (data.type == 'Users' || data.type == 'Contacts'))"
      [style.background]="getColor(5)"  class="profile_ML">{{getuserFLnames(DialogData?.firstName+' '+DialogData?.lastName)}}</div>
      <img *ngIf="data.type == 'Sites'" style="width: 30px; height: 30px;" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
      <img style="width: 30px; height: 30px;" *ngIf="data.type == 'Products'"[src]="DialogData?.profilePicture ? DialogData?.profilePicture : '../../../../../assets/images/iconsForsvg/icon_product.svg'">
      <mat-icon *ngIf="data.type == 'Transactions'" style="color:rgb(100, 149, 237)"> shopping_cart</mat-icon>
      <mat-icon *ngIf="data.type == 'Orders'" style="color:rgb(100, 149, 237)"> assignment</mat-icon>
      <mat-icon *ngIf="data.type == 'Tasks' && this.DialogData.isMileStone == false" style="color:green">assignment_turned_in</mat-icon>
      <mat-icon *ngIf="data.type == 'Initiatives'" style="color:red"> ballot</mat-icon>
      <span *ngIf="data.type == 'Tasks' && this.DialogData.isMileStone == true">
        <img style="width: 30px; height: 30px;"src="../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">
      </span>
      <span *ngIf="data.type == 'Automation'">
        <img style="width: 30px; height: 30px;" src="../../../../assets/images/iconsForsvg/setting-automation.svg">
      </span>
       </div>
    <div style="margin-left: 5px;">
      <h2 *ngIf="data.type == 'Users' || data.type == 'Contacts'">{{DialogData.firstName|titlecase}} {{DialogData.lastName|titlecase}}</h2>
      <h2 *ngIf="data.type == 'Sites'">{{DialogData.companyName|titlecase}}</h2>
      <h2 *ngIf="data.type == 'Products'">{{DialogData.productName|titlecase}}</h2>
      <h2 *ngIf="data.type == 'Transactions'">{{DialogData.transactionReference}}</h2>
      <h2 *ngIf="data.type == 'Orders'">{{DialogData.orderId}}</h2>
      <h2 *ngIf="data.type == 'Tasks'">{{DialogData.title}}</h2>
      <h2 *ngIf="data.type == 'Initiatives'">{{DialogData.title}}</h2>
      <h2 *ngIf="data.type == 'Automation'">{{DialogData.name}}</h2>
    </div>
      </div>
      <div>
        <mat-paginator [pageSize]="10" [length]="logDataLength" (page)="pagnext($event)"></mat-paginator>
      </div>
    </div>
   </ng-container>
    <!-- 00000000000  -->
    <ng-container *ngIf="ActivitLogdata.length ==0 || ActivitLogdata.length == ''">
      <div class="row mt-5">
        <div class="col-12 p-0 nodata_text">
          <span>No Activity Logs for this {{data.Entity == 'User' ? 'User' : data.Entity == 'Contacts' ? 'Contact' :
            data.Entity == 'Sites' ? 'Site' : data.Entity == 'Product' ? 'Product' : data.Entity == 'Transaction' ?
            'Transaction' : data.Entity == 'Task' ? 'Task' : 'Initiative'}}
            <img
              *ngIf="((DialogData?.profilePicture) && (data.type == 'Users' || data.type == 'Contacts' || data.type == 'Products'))"
              class="avatar" style="border-radius: 50%;margin-top: -8px;"
              [src]="DialogData?.profilePicture ? DialogData?.profilePicture : DialogData?.profile">

            <div *ngIf="((DialogData?.profilePicture == '' || DialogData?.profilePicture == null) && (data.type == 'Users' || data.type == 'Contacts'))"
            [style.background]="getColor(5)"  class="profile_ML">{{getuserFLnames(DialogData?.firstName+' '+DialogData?.lastName)}}</div>
            
            <span *ngIf="data.type == 'Sites'">
              <img style="width: 30px; height: 30px;" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg">
            </span>
            <span *ngIf="((data.type == 'Products') && (DialogData.profile == null))">
              <img style="width: 30px; height: 30px;"
                [src]="DialogData?.profilePicture ? DialogData.profilePicture : '../../../../../assets/images/iconsForsvg/icon_product.svg'">
            </span>
            <mat-icon *ngIf="data.type == 'Transactions'" style="color:rgb(100, 149, 237)">
              shopping_cart</mat-icon>
            <mat-icon *ngIf="data.type == 'Orders'" style="color:rgb(100, 149, 237)"> assignment</mat-icon>
            <mat-icon *ngIf="data.type == 'Tasks' && this.DialogData.isMileStone == false" style="color:green">
              assignment_turned_in</mat-icon>
            <mat-icon *ngIf="data.type == 'Initiatives'" style="color:red"> ballot</mat-icon>
            <span *ngIf="data.type == 'Tasks' && this.DialogData.isMileStone == true">
              <img style="width: 30px; height: 30px;"
                src="../../../../assets/images/iconsForsvg/nearby_FILL0_wght400_GRAD0_opsz24.svg">

            </span>
            <b *ngIf="data.type == 'Users' || data.type == 'Contacts'">{{DialogData.firstName + ' ' +
              DialogData.lastName}}</b>
            <b *ngIf="data.type == 'Sites'">{{DialogData.companyName|titlecase}}</b>
            <b *ngIf="data.type == 'Products'">{{DialogData.productName|titlecase}}</b>
            <b *ngIf="data.type == 'Transactions'">{{DialogData.transactionReference}}</b>
            <b *ngIf="data.type == 'Orders'">{{DialogData.orderId}}</b>
            <b *ngIf="data.type == 'Tasks'">{{DialogData.title}}</b>
            <b *ngIf="data.type == 'Initiatives'">{{DialogData.title}}</b>
          </span>
        </div>
      </div>
    </ng-container>
    <!-- body data  -->
    <ng-container *ngIf="ActivitLogdata.length !=0 || ActivitLogdata.length != ''">
      <div *ngFor="let x of ActivitLogdata;let index=index;">
        <div style="background-color: #e2f2e6;display: flex;border-bottom: 1px solid black;border-top: 1px solid black;">
          <!-- width:12%; -->
          <div style="width:12%;">
            <mat-icon *ngIf="x.entity == 'Task'" style="color:green;padding: 12px;"> assignment_turned_in</mat-icon>
            <mat-icon *ngIf="x.entity == 'User'" style="color:#fb4444;padding: 12px;"> person</mat-icon>
            <mat-icon *ngIf="x.entity == 'Event'" style="color:#fb4444;padding: 12px;"> event</mat-icon>
            <mat-icon *ngIf="x.entity == 'Contacts'" style="color:#6495ed;padding: 12px;"> contacts</mat-icon>
            <img *ngIf="x.entity == 'Sites'" src="../../../../../assets/images/iconsForsvg/Site_Icon.svg"style="width: 25px; height: 25px; margin-top: 8px; margin-left: 6px;">
            <mat-icon *ngIf="x.entity == 'Product'" style="color:#fb4444;padding: 12px;"> business_center</mat-icon>
            <mat-icon *ngIf="x.entity == 'Initiative'" style="color:#fb4444;padding: 12px;"> ballot</mat-icon>
            <mat-icon *ngIf="x.entity == 'Transaction' || x.entity == 'Order'" style="color:#6495ed;padding: 12px;">shopping_cart</mat-icon>
            <img *ngIf="x.entity == 'Email'" src="../../../../assets/notecreateicons/Gmail.svg" style="width: 25px; height: 25px; margin-top: 8px; margin-left: 15px;">
            <img *ngIf="x.entity == 'Note' && x?.noteDto?.mediaType == 'Gmail'" src="../../../../assets/notecreateicons/Gmail.svg" style="width: 25px; height: 25px; margin-top: 8px; margin-left: 15px;">
            <img *ngIf="x.entity == 'Note' && x?.noteDto?.mediaType == 'Outlook'" src="../../../../assets/notecreateicons/Outlook.svg"style="width: 25px; height: 25px; margin-top: 8px; margin-left: 15px;">
          </div>
          <!-- width:50%  -->
           <div style="width: 50%;">
          <!-- no Note no email no User -->
          <ng-container *ngIf="x.entity != 'Note'&&x.entity != 'Email'&&x.entity != 'User'">
            <div style="display: flex;" >
              <div *ngIf="x.profilePicture == null || x.profilePicture == ''" style="margin-top: 5px;" [style.background]="getColor(index)"  class="profile_ML">{{getuserFLnames(x.title)}}</div>
              <p class="user_name" style="font-weight:600;margin-top:12px;font-size: 14px;">{{x.title}}</p>
            </div>
           </ng-container>
          <!-- Note  -->
           <ng-container *ngIf="x.entity == 'Note'">
             <div style="display: flex;">
              <div style="width:20%" >
                  <div *ngIf="x.profilePicture == null || x.profilePicture == ''" style="margin-top: 5px;" [style.background]="getColor(index)"  class="profile_ML">{{getuserFLnames(x?.noteDto?.senderName)}}</div>
              </div>
              <div style="width:80%;">
                <p class="user_name"  style="font-weight:600;color:black; font-size: 12px;margin: 0 !important">{{(x.noteDto?.senderName)}}</p>
                <p class="user_name"  style="font-weight:600;color:gray; font-size: 12px;margin: 0 !important">{{x.noteDto?.senderEmail}}</p>
              </div>
             </div>
           </ng-container>


          <!-- Email  -->
           <ng-container *ngIf="x.entity == 'Email'&&x.mailDto!=null">
           <div style="display: flex;">
            <div style="width:20%">
                <div *ngIf="x.profilePicture == null || x.profilePicture == ''" style="margin-top: 5px;" [style.background]="getColor(index)"  class="profile_ML">{{getuserFLnames(x.mailDto?.senderName)}}</div>
            </div>
            <div style="width:80%;">
              <p class="user_name"  style="font-weight:600;color:black; font-size: 12px;margin: 0 !important">{{x.mailDto?.senderName}}</p>
              <p class="user_name"  style="font-weight:600;color:gray; font-size: 12px;margin: 0 !important">{{x.mailDto?.senderEmail}}</p>
            </div>
           </div>
           </ng-container>
           <!-- User  -->
           <ng-container *ngIf="x.entity == 'User'">
            <p class="user_name" style="font-weight:600;margin-top:12px;font-size: 14px;">{{x.title}}</p>
           </ng-container>


           </div>
           <!-- width: 35%;"  -->
          <div style="width: 35%;">
            <div class="row w-100" style="margin-top:12px">
              <p style="font-size: 12px;margin-right:8px;">{{ x.logTime | date: 'dd-MMM-yyyy hh:mm:ss' }}</p>
            </div>
          </div>
          <!-- width: 15%;  -->
          <div style="width: 15%;">
            <div class="row w-100" style="margin-top:12px">
              <button [matMenuTriggerFor]="beforeMenu" style="display: flex;justify-content: end;position: relative;" class="mr-1 a-color">
                <mat-icon class="icon-options">more_vert</mat-icon>
            </button>
            <!-- options  -->
            <mat-menu  #beforeMenu="matMenu" xPosition="before">
              <button *ngIf="x.entity == 'Event'" class="fw-600" mat-menu-item
              (click)="openEditdEventDialog(x?.entityId)">
                  <mat-icon>edit</mat-icon> {{'Edit'|translate}}
              </button>
              <button *ngIf="x.entity == 'Event'" class="fw-600" mat-menu-item (click)="deleteDialogConfirmation(eventData,'deleteEvent')">
                <mat-icon>delete</mat-icon> {{'Delete'|translate}}
              </button>
              <button *ngIf="(AdminStatus=='true' && x.entity == 'User')" class="fw-600" mat-menu-item 
                  (click)="updateuserDialog(x.entityId)">
                  <mat-icon>edit</mat-icon> {{'Edit'|translate}}
              </button>
              <button *ngIf="(AdminStatus=='true' && x.entity == 'User')" class="fw-600" mat-menu-item
                  (click)="deleteDialogConfirmation(x,'deleteUser')">
                  <mat-icon>delete</mat-icon> {{'Delete'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="openAddEventDialog()">
                  <mat-icon>event</mat-icon> {{'Create an Event'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="ActivityReport(x,true, 'Users')">
                  <mat-icon style="color:green">bar_chart</mat-icon> {{'Create an Activity Report'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="CreateStratergy()">
                  <mat-icon>wb_incandescent</mat-icon> {{'Create Stratergy'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item>
                  <mat-icon>vpn_key</mat-icon> {{'Edit Permission'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="AddFile(x,'single')">
                  <mat-icon style="color:rgb(66,135,245) ;">
                      insert_drive_file</mat-icon>
                  {{'Add File'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="AddNote(x,'single')">
                  <mat-icon style="color:rgb(255,193,111) ;">
                      speaker_notes </mat-icon>
                  {{'Add Note'|translate}}
              </button>
              <button *ngIf="x.entity == 'User'" class="fw-600" mat-menu-item (click)="createTarget(x)">
                  <mat-icon>track_changes</mat-icon> {{'Create Target'|translate}}
              </button>

              <button class="fw-600" mat-menu-item *ngIf="(AdminStatus=='true' && x.entity == 'Contacts')"
                        (click)="updateContactDialog(x.entityId)">
                        <mat-icon>edit</mat-icon> {{"Edit"|translate}}
                    </button>
                    <button class="fw-600" mat-menu-item *ngIf="(AdminStatus=='true' && x.entity == 'Contacts')"
                        (click)="deleteDialogConfirmation(x,'deleteContact')">
                        <mat-icon>delete</mat-icon> {{"Delete"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="openAddEventDialog()">
                        <mat-icon>event</mat-icon> {{"Create an Event"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="ActivityReport(x,true, 'Contacts')">
                        <mat-icon style="color:green">bar_chart</mat-icon> {{"Create an Activity Report"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="CreateStratergy()">
                        <mat-icon>wb_incandescent</mat-icon> {{"Create Stratergy"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item>
                        <mat-icon class="yellow">assignment</mat-icon> {{"Create Quote"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600 " mat-menu-item>
                        <mat-icon class="blue">assignment</mat-icon>{{"Create Invoice"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="AddFile(x,'single')">
                        <mat-icon style="color:rgb(66,135,245) ;">
                            insert_drive_file</mat-icon>
                        {{"Add File"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="AddNote(x,'single')">
                        <mat-icon style="color:rgb(255,193,111) ;">
                            speaker_notes </mat-icon>
                        {{"Add Note"|translate}}
                    </button>
                    <button *ngIf="x.entity == 'Contacts'" class="fw-600" mat-menu-item (click)="createTarget(x)">
                        <mat-icon>track_changes</mat-icon> {{"Create Target"|translate}}
                    </button>
                    <!-- for mails log  -->
                     <ng-container *ngIf="x.entity == 'Email'">
                            <button class="fw-600" mat-menu-item (click)="Replyemail(x)"> 
                                <img  src="../../../assets/images/iconsForsvg/reply.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item (click)="Replyemail(x)">
                                <img  src="../../../assets/images/iconsForsvg/reply_all.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Reply All'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item (click)="Forwardemail(x)">
                                <img  src="../../../assets/images/iconsForsvg/forward.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Forward'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item >
                                <img  src="../../../assets/images/iconsForsvg/print.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Print'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item >
                                <img  src="../../../assets/images/iconsForsvg/delete.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Delete'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item >
                                <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark spam'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item >
                                <img  src="../../../assets/images/iconsForsvg/block.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'block'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item >
                                <img  src="../../../assets/images/iconsForsvg/download.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Download'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item >
                                <img  src="../../../assets/images/iconsForsvg/mark_email_unread.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Mark as unread'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item (click)="CreateTask(x)" >
                                <img  src="../../../assets/images/iconsForsvg/task.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Task'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item (click)="CreateEvent(x)">
                                <img  src="../../../assets/images/iconsForsvg/calendar.svg">
                                <span class="fw-600 color_black" style="margin-left: 5px;">{{'Create Event'|translate}}</span>
                            </button>
                            <button class="fw-600" mat-menu-item (click)="ConverttoContact(x)">
                              <img  src="../../../assets/images/iconsForsvg/contacts.svg">
                              <span class="fw-600 color_black" style="margin-left: 5px;">{{'Convert to Contact'|translate}}</span>
                          </button>
                     </ng-container>
          </mat-menu>
            </div>
          </div>
        </div>


        <ng-container *ngIf="x.entity != 'Note'&&x.entity != 'Email'">
          <div class="rich-text mt-2 mb-2" style="margin:10px" >
            <p style="font-weight:500">{{x.message}}</p>
          </div>
        </ng-container>
        <ng-container *ngIf="x.entity == 'Email'&&x.mailDto!=null">
          <!-- To CC Bcc  -->
          <div *ngIf="x.mailDto?.receipientEmail.length!=0">
            <h5 style="color: grey;font-weight: 900;">To : <span *ngFor="let x of x.mailDto?.receipientEmail">{{x}}</span></h5>
          </div>
          <div *ngIf="x.mailDto?.ccEmails.length!=0">
            <h5 style="color: grey;font-weight: 900;">CC : <span *ngFor="let x of x.mailDto?.ccEmails">{{x}}</span></h5>
          </div>
          <div *ngIf="x.mailDto?.bccEmails.length!=0">
            <h5 style="color: grey;font-weight: 900;">BcC : <span *ngFor="let x of x.mailDto?.bccEmails">{{x}}</span></h5>
          </div>
          <!-- subject  -->
          <div class="rich-text mt-2 mb-2" style="margin:10px" >
            <p  class="styleforcontent">{{x.mailDto?.subject|titlecase}}</p>
            <div  [innerHTML]="x.mailDto?.bodyContent"></div>
          </div>
          <!-- <div class="rich-text mt-2" style="margin:10px" [innerHTML]="httpGet(x.mailDto?.subNoteUrl)">
          </div> -->
          <ng-container *ngIf="x.mailDto?.attachments!=null&&x.mailDto?.attachments.length!=0&&x.mailDto!=null">
            <div class="row mb-2">
              <div class="col-4" style="padding: 0 !important;" *ngFor="let x of x.mailDto?.attachments">
                 <div style="display: flex;">
                  <div>
                    <img src="../../../../assets/notecreateicons/doc-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='docx'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/png-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='png'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/png-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='PNG'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/jpg-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='jpg'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/xls-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='xlsx'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/avi-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='avi'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/pdf-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='pdf'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/ppt-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='powerpoint'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/svg.svg" *ngIf="getfiletype(x.fileName)=='svg'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/html-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='html'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/csv-svgrepo-com.svg" (click)="View(data)" *ngIf="getfiletype(x.fileName)=='csv'" class="drowdownimage" >
                    <img src="../../../../assets/notecreateicons/txt image.jpg" (click)="View(data)" *ngIf="getfiletype(x.fileName)=='txt'" class="drowdownimage" >
                  </div>
                  <div> 
                    <span style="font-size: 12px;"><b>{{x.fileName|titlecase}}</b></span>
                  </div>
                 </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="x.entity == 'Note'">
            <div class="rich-text mt-3 mb-3" style="margin:10px">
              <span class="styleforcontent">{{x.noteDto?.subject|titlecase}}</span>
            </div>
            <div *ngIf="x.noteDto?.ccRecipients != ''">
              <h5 style="color: grey;font-weight: 900;">CC : {{x.noteDto?.ccRecipients}}</h5>
            </div>
            <!-- <div class="rich-text mt-2" style="margin:10px" [innerHTML]="httpGet(x.noteDto?.subNoteUrl)">
            </div> -->
            <ng-container *ngIf="x.noteDto?.subNoteAttachments.length!=0">
              <div class="row mb-2">
                <div class="col-4" style="padding: 0 !important;" *ngFor="let x of x.noteDto?.subNoteAttachments">
                   <div style="display: flex;">
                    <div>
                      <img src="../../../../assets/notecreateicons/doc-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='docx'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/png-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='png'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/png-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='PNG'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/jpg-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='jpg'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/xls-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='xlsx'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/avi-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='avi'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/pdf-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='pdf'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/ppt-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='powerpoint'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/html-svgrepo-com.svg" *ngIf="getfiletype(x.fileName)=='html'" class="drowdownimage" >
                      <img src="../../../../assets/notecreateicons/csv-svgrepo-com.svg" (click)="View(data)" *ngIf="getfiletype(x.fileName)=='csv'" class="drowdownimage" >
                    </div>
                    <div>
                      <span style="font-size: 12px;"><b>{{x.fileName|titlecase}}</b></span>
                    </div>
                   </div>
                </div>
              </div>
            </ng-container>
        </ng-container>
      
      </div>
    </ng-container>

  </mat-dialog-content>

  <div class="overlay" *ngIf="spinnerLoader">
    <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>